import {useEffect, useState} from "react";

import {useMediaQuery} from "react-responsive";
import Loading from "./Loading";
import ExcelDownload from "./ExcelDownload";

const DataTable = ({ data, loading, setLoading, setData, setSearchedData, searchedData }) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 1223px)' });

    const [forDate, setForDate] = useState('');  // 상태를 컴포넌트 내에서 정의
    const [searchType, setSearchType] = useState('A');
    // 각각 다르게 sort해서 넣을 state
    // 랭킹순
    const [best, setBest] = useState(null);
    // 클릭순
    const [click, setClick] = useState(null);
    // 판매순
    const [sell, setSell] = useState(null);
    // 금액순
    const [price, setPrice] = useState(null);
    // 품질점수순 qualityStarScore
    const [quality, setQuality] = useState(null);



    const handleCopyToClipboard = (nvmid) => {
        // nvmid 값을 클립보드에 복사
        navigator.clipboard.writeText(nvmid)

    };



    // Update sorting states when data changes
    useEffect(() => {
        if (data != null && Object.keys(data).length > 0 ) {

            const bestData = [...data.result.products].sort((a, b) => a.best - b.best);
            const bestData1 = [...data.result.products].sort((a, b) => a.best - b.best);
            const clickData = [...data.result.products].sort((a, b) => b.hitStarScore - a.hitStarScore);
            const sellData = [...data.result.products].sort((a, b) => b.saleStarScore - a.saleStarScore);
            const priceData = [...data.result.products].sort((a, b) => b.lowPrice - a.lowPrice);
            const qualityData = [...data.result.products].sort((a, b) => b.qualityStarScore - a.qualityStarScore)

            setBest(bestData);

            setClick(clickData);

            setSell(sellData);

            setPrice(priceData);

            setQuality(qualityData);
        }
    }, [data]);  // This will run when `data` changes

    useEffect(() => {
        if (data != null && data.result) {
            const dateString = data.result.searchTime;  // searchTime을 가져옴
            const date = new Date(dateString);
            const formattedDate = date.toLocaleString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',', '');  // 쉼표 제거

            setForDate(formattedDate);  // 상태 업데이트
        }
    }, [data]);


    // searchType 변경 처리 함수
    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
    };


    return (

        <div>
            {
                loading ?
                    <Loading></Loading>
                    :null
            }
            {
                searchedData && searchedData.length > 0 ?
                    <div className="">
                        <div className="py-10 w-[95%] pl-[3%]">
                            <hr className="border-gray-400"></hr>
                        </div>
                        <div className="pl-[3%] pb-3">
                            <div className="flex text-4xl">
                                <span>검색결과: </span>
                                <span className="text-blue-500"> {searchedData.length}</span>
                                <span>건</span>
                            </div>
                        </div>
                        <div className="flex justify-center relative  items-center w-[95%] mx-auto">

                            <table
                                className="w-full text-sm  rtl:text-right text-gray-500 dark:text-gray-400 text-center border-gray-300 border table-fixed">
                                <thead
                                    className="font-bold text-yellow-600 text-[15px] uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr className="p-2">
                                    <td scope="col" className="w-[40px] py-3 border-gray-300 border">순위</td>
                                    <td scope="col" className="w-[480px] py-3 border-gray-300 border">상품명</td>
                                    <td scope="col" className="w-[108px] py-3 border-gray-300 border">스토어명</td>
                                    <td scope="col" className="w-[74px] py-3 border-gray-300 border">판매가</td>
                                    <td scope="col" className="w-[66px] py-3 border-gray-300 border">찜수</td>
                                    <td scope="col" className="w-[66px] py-3 border-gray-300 border">리뷰수</td>
                                    <td scope="col" className="w-[61px] py-3 border-gray-300 border">클릭점수</td>
                                    <td scope="col" className="w-[60px] py-3 border-gray-300 border">판매점수</td>
                                    <td scope="col" className="w-[61px] py-3 border-gray-300 border">리뷰점수</td>
                                    <td scope="col" className="w-[61px] py-3 border-gray-300 border">품질점수</td>
                                    <td scope="col" className="w-[50px] py-3 border-gray-300 border">연관성</td>
                                    <td scope="col" className="w-[50px] py-3 border-gray-300 border">유사성</td>
                                    <td scope="col" className="w-[50px] py-3 border-gray-300 border">최신성</td>
                                    <td scope="col" className="w-[61px] py-3 border-gray-300 border">하락위험</td>
                                    <td scope="col" className="w-[50px] py-3 border-gray-300 border">신뢰도</td>
                                    <td scope="col" className="w-[50px] py-3 border-gray-300 border">고유번호</td>
                                </tr>
                                </thead>
                                <tbody className="border-gray-300 border font-bold text-lg">
                                {
                                    searchedData.map((product, i) => (
                                        <tr key={i} className="border-gray-300 border">
                                            <td className="border-gray-300 border">{product.rank}</td>
                                            <td className="border-gray-300 border">
                                                <div className="flex">
                                                    <div>
                                                        <img
                                                            className="w-auto h-[85px] object-contain m-0 p-0"
                                                            src={product.imageUrl}
                                                            alt="Product Image"
                                                        />

                                                    </div>
                                                    <div>
                                                        {
                                                            product.link === '' ?
                                                                <p className="text-lg font-bold pt-3 ml-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                :

                                                                <a href={product.link} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    <p className="text-lg font-bold pt-3 ml-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                </a>
                                                        }


                                                        <div
                                                            className="flex mt-3 w-full text-[12px]  ml-3">
                                                            {
                                                                product.largeCategoryName ?
                                                                    <span>{product.largeCategoryName}</span>
                                                                    : null
                                                            }
                                                            {
                                                                product.middleCategoryName ?
                                                                    <span> > {product.middleCategoryName}</span>
                                                                    : null
                                                            }
                                                            {
                                                                product.smallCategoryName ?
                                                                    <span> > {product.smallCategoryName}</span>
                                                                    : null
                                                            }
                                                            {
                                                                product.detailCategoryName ?
                                                                    <span> > {product.detailCategoryName}</span>
                                                                    : null
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="border-gray-300 borde ">
                                                {
                                                    product.mallCount === 0
                                                        ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                        : `판매처: ${product.mallCount} 곳`
                                                }


                                            </td>
                                            <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                            <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                            <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                            <td className="border-gray-300 border">{product.hitStarScore}</td>
                                            <td className="border-gray-300 border">{product.saleStarScore}</td>
                                            <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                            <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                            <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                            <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                            <td className="border-gray-300 border">{product.recentStarScore}</td>
                                            <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                            <td className="border-gray-300 border">{product.reliabilityType}</td>
                                            <td
                                                className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                            >
                                                {product.nvmid}
                                            </td>

                                        </tr>

                                    ))
                                }
                                </tbody>


                            </table>
                        </div>
                        <div className="py-10 w-[95%] pl-[3%]">
                            <hr className="border-gray-400"></hr>
                        </div>

                    </div>
                    : null
            }


            {
                data && Object.keys(data).length > 0 ?
                    <div className="flex justify-between">
                        <div className="flex justify-center items-end pl-12">
                            <p className="text-left p-2">※ 데이터 기준 시간 : {forDate}</p>

                        </div>
                        <div className="flex justify-end text-end py-1 pr-[2.5%]">
                            {/*<button className="bg-red-600 text-white font-bold p-2 rounded-md mr-2">스크린샷</button>*/}

                            <div className="flex content-end">
                                <p className="text-sm text-gray-500 text-right items-end pt-3">※고유번호란 클릭 시 자동으로 클립보드에 복사됩니다</p>
                                <select onChange={handleSearchTypeChange}
                                        className="text-lg rounded-md border border-gray-600 p-2 ml-6 text-center content-end">
                                    <option value="A">랭킹순</option>
                                    <option value="B">클릭순</option>
                                    <option value="C">판매순</option>
                                    <option value="D">가격순</option>
                                    <option value="E">품질순</option>
                                </select>
                            </div>
                            <ExcelDownload data={data} best={best} sell={sell} click={click} price={price}
                                           quality={quality}
                                           forDate={forDate} searchType={searchType}></ExcelDownload>
                        </div>
                    </div>

                    : null
            }
            {
                data && Object.keys(data).length > 0 ?
                    <div className="flex justify-end text-end py-1 pr-[2.5%]">
                        {/*<button className="bg-red-600 text-white font-bold p-2 rounded-md mr-2">스크린샷</button>*/}


                    </div>
                    : null
            }
            {
                data && Object.keys(data).length > 0 ?
                    <div className="flex justify-center relative  items-center w-[95%] mx-auto">

                        <table
                            className="w-full text-sm  rtl:text-right text-gray-500 dark:text-gray-400 text-center border-gray-300 border table-fixed">
                            <thead
                                className="font-bold text-yellow-600 text-[15px] sticky top-0 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="p-2">
                                <td scope="col" className="w-[40px] py-3 border-gray-300 border">순위</td>
                                <td scope="col" className="w-[480px] py-3 border-gray-300 border">상품명</td>
                                <td scope="col" className="w-[108px] py-3 border-gray-300 border">스토어명</td>
                                <td scope="col" className="w-[74px] py-3 border-gray-300 border">판매가</td>
                                <td scope="col" className="w-[66px] py-3 border-gray-300 border">찜수</td>
                                <td scope="col" className="w-[66px] py-3 border-gray-300 border">리뷰수</td>
                                <td scope="col" className="w-[61px] py-3 border-gray-300 border">클릭점수</td>
                                <td scope="col" className="w-[60px] py-3 border-gray-300 border">판매점수</td>
                                <td scope="col" className="w-[61px] py-3 border-gray-300 border">리뷰점수</td>
                                <td scope="col" className="w-[61px] py-3 border-gray-300 border">품질점수</td>
                                <td scope="col" className="w-[50px] py-3 border-gray-300 border">연관성</td>
                                <td scope="col" className="w-[50px] py-3 border-gray-300 border">유사성</td>
                                <td scope="col" className="w-[50px] py-3 border-gray-300 border">최신성</td>
                                <td scope="col" className="w-[61px] py-3 border-gray-300 border">하락위험</td>
                                <td scope="col" className="w-[50px] py-3 border-gray-300 border">신뢰도</td>
                                <td scope="col" className="w-[50px] py-3 border-gray-300 border">고유번호</td>
                            </tr>
                            </thead>
                            {
                                searchType === 'A' ?
                                    <tbody className="border-gray-300 border font-bold text-lg">
                                    {
                                        data.result.products.map((product, i) => (
                                            <tr key={i} className="border-gray-300 border">
                                                <td className="border-gray-300 border">{product.rank}</td>
                                                <td className="border-gray-300 border">
                                                    <div className="flex">
                                                        <div>
                                                            <img
                                                                className="w-auto h-[85px] object-contain m-0 p-0"
                                                                src={product.imageUrl}
                                                                alt="Product Image"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                product.link === '' ?
                                                                    <p className="text-lg font-bold pt-3 ml-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    :

                                                                    <a href={product.link} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <p className="text-lg font-bold pt-3 ml-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    </a>
                                                            }


                                                            <div
                                                                className="flex mt-3 w-full text-[12px]  ml-3">
                                                                {
                                                                    product.largeCategoryName ?
                                                                        <span>{product.largeCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.middleCategoryName ?
                                                                        <span> > {product.middleCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.smallCategoryName ?
                                                                        <span> > {product.smallCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.detailCategoryName ?
                                                                        <span> > {product.detailCategoryName}</span>
                                                                        : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-gray-300 borde ">
                                                    {
                                                        product.mallCount === 0
                                                            ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                            : `판매처: ${product.mallCount} 곳`
                                                    }


                                                </td>
                                                <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                                <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.hitStarScore}</td>
                                                <td className="border-gray-300 border">{product.saleStarScore}</td>
                                                <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                                <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                                <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                                <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                                <td className="border-gray-300 border">{product.recentStarScore}</td>
                                                <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                                <td className="border-gray-300 border">{product.reliabilityType}</td>
                                                <td
                                                    className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                    onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                                >
                                                    {product.nvmid}
                                                </td>

                                            </tr>

                                        ))
                                    }
                                    </tbody>
                                    : null
                            }
                            {
                                searchType === 'B' ?
                                    <tbody className="border-gray-300 border font-bold text-lg">
                                    {
                                        click.map((product, j) => (
                                            <tr key={j} className="border-gray-300 border">
                                                <td className="border-gray-300 border">{product.rank}</td>
                                                <td className="border-gray-300 border">
                                                    <div className="flex">
                                                        <div>
                                                            <img
                                                                className="w-auto h-[85px] object-contain m-0 p-0"
                                                                src={product.imageUrl}
                                                                alt="Product Image"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                product.link === '' ?
                                                                    <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    :

                                                                    <a href={product.link} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    </a>
                                                            }


                                                            <div
                                                                className="flex mt-3 w-full text-[12px]">
                                                                {
                                                                    product.largeCategoryName ?
                                                                        <span>{product.largeCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.middleCategoryName ?
                                                                        <span> > {product.middleCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.smallCategoryName ?
                                                                        <span> > {product.smallCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.detailCategoryName ?
                                                                        <span> > {product.detailCategoryName}</span>
                                                                        : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-gray-300 borde ">
                                                    {
                                                        product.mallCount === 0
                                                            ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                            : `판매처: ${product.mallCount} 곳`
                                                    }


                                                </td>
                                                <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                                <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.hitStarScore}</td>
                                                <td className="border-gray-300 border">{product.saleStarScore}</td>
                                                <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                                <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                                <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                                <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                                <td className="border-gray-300 border">{product.recentStarScore}</td>
                                                <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                                <td className="border-gray-300 border">{product.reliabilityType}</td>
                                                <td
                                                    className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                    onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                                >
                                                    {product.nvmid}
                                                </td>
                                            </tr>

                                        ))
                                    }
                                    </tbody>
                                    : null
                            }
                            {
                                searchType === 'C' ?
                                    <tbody className="border-gray-300 border font-bold text-lg">
                                    {
                                        sell.map((product, k) => (
                                            <tr key={k} className="border-gray-300 border">
                                                <td className="border-gray-300 border">{product.rank}</td>
                                                <td className="border-gray-300 border">
                                                    <div className="flex">
                                                        <div>
                                                            <img
                                                                className="w-auto h-[85px] object-contain m-0 p-0"
                                                                src={product.imageUrl}
                                                                alt="Product Image"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                product.link === '' ?
                                                                    <p className="text-lg font-bold pt-3 truncate w-40">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>

                                                                    :

                                                                    <a href={product.link} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    </a>
                                                            }


                                                            <div
                                                                className="flex mt-3 w-full text-[12px]">
                                                                {
                                                                    product.largeCategoryName ?
                                                                        <span>{product.largeCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.middleCategoryName ?
                                                                        <span> > {product.middleCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.smallCategoryName ?
                                                                        <span> > {product.smallCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.detailCategoryName ?
                                                                        <span> > {product.detailCategoryName}</span>
                                                                        : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-gray-300 borde ">
                                                    {
                                                        product.mallCount === 0
                                                            ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                            : `판매처: ${product.mallCount} 곳`
                                                    }


                                                </td>
                                                <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                                <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.hitStarScore}</td>
                                                <td className="border-gray-300 border">{product.saleStarScore}</td>
                                                <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                                <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                                <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                                <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                                <td className="border-gray-300 border">{product.recentStarScore}</td>
                                                <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                                <td className="border-gray-300 border">{product.reliabilityType}</td>
                                                <td
                                                    className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                    onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                                >
                                                    {product.nvmid}
                                                </td>
                                            </tr>

                                        ))
                                    }
                                    </tbody>
                                    : null
                            }
                            {
                                searchType === 'D' ?
                                    <tbody className="border-gray-300 border font-bold text-lg">
                                    {
                                        price.map((product, l) => (
                                            <tr key={l} className="border-gray-300 border">
                                                <td className="border-gray-300 border">{product.rank}</td>
                                                <td className="border-gray-300 border">
                                                    <div className="flex">
                                                        <div>
                                                            <img
                                                                className="w-auto h-[85px] object-contain m-0 p-0"
                                                                src={product.imageUrl}
                                                                alt="Product Image"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                product.link === '' ?
                                                                    <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    :

                                                                    <a href={product.link} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    </a>
                                                            }


                                                            <div
                                                                className="flex mt-3 w-full text-[12px]">
                                                                {
                                                                    product.largeCategoryName ?
                                                                        <span>{product.largeCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.middleCategoryName ?
                                                                        <span> > {product.middleCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.smallCategoryName ?
                                                                        <span> > {product.smallCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.detailCategoryName ?
                                                                        <span> > {product.detailCategoryName}</span>
                                                                        : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-gray-300 borde ">
                                                    {
                                                        product.mallCount === 0
                                                            ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                            : `판매처: ${product.mallCount} 곳`
                                                    }


                                                </td>
                                                <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                                <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.hitStarScore}</td>
                                                <td className="border-gray-300 border">{product.saleStarScore}</td>
                                                <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                                <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                                <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                                <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                                <td className="border-gray-300 border">{product.recentStarScore}</td>
                                                <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                                <td className="border-gray-300 border">{product.reliabilityType}</td>
                                                <td
                                                    className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                    onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                                >
                                                    {product.nvmid}
                                                </td>
                                            </tr>

                                        ))
                                    }
                                    </tbody>
                                    : null
                            }
                            {
                                searchType === 'E' ?
                                    <tbody className="border-gray-300 border font-bold text-lg">
                                    {
                                        quality.map((product, l) => (
                                            <tr key={l} className="border-gray-300 border">
                                                <td className="border-gray-300 border">{product.rank}</td>
                                                <td className="border-gray-300 border">
                                                    <div className="flex">
                                                        <div>
                                                            <img
                                                                className="w-auto h-[85px] object-contain m-0 p-0"
                                                                src={product.imageUrl}
                                                                alt="Product Image"
                                                            />

                                                        </div>
                                                        <div>
                                                            {
                                                                product.link === '' ?
                                                                    <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    :

                                                                    <a href={product.link} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <p className="text-lg font-bold pt-3">{product.productTitle.length > 40 ? `${product.productTitle.slice(0, 40)}...` : product.productTitle}</p>
                                                                    </a>
                                                            }


                                                            <div
                                                                className="flex mt-3 w-full text-[12px]">
                                                                {
                                                                    product.largeCategoryName ?
                                                                        <span>{product.largeCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.middleCategoryName ?
                                                                        <span> > {product.middleCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.smallCategoryName ?
                                                                        <span> > {product.smallCategoryName}</span>
                                                                        : null
                                                                }
                                                                {
                                                                    product.detailCategoryName ?
                                                                        <span> > {product.detailCategoryName}</span>
                                                                        : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-gray-300 borde ">
                                                    {
                                                        product.mallCount === 0
                                                            ? `${product.mallName.length > 9 ? `${product.mallName.slice(0, 9)}...` : product.mallName}`
                                                            : `판매처: ${product.mallCount} 곳`
                                                    }


                                                </td>
                                                <td className="border-gray-300 border">{product.lowPrice.toLocaleString()}원</td>
                                                <td className="border-gray-300 border">{product.keepCnt.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.reviewCount.toLocaleString()}</td>
                                                <td className="border-gray-300 border">{product.hitStarScore}</td>
                                                <td className="border-gray-300 border">{product.saleStarScore}</td>
                                                <td className="border-gray-300 border">{product.reviewCountStarScore}</td>
                                                <td className="border-gray-300 border">{product.qualityStarScore}</td>
                                                <td className="border-gray-300 border">{product.relevanceStarScore}</td>
                                                <td className="border-gray-300 border">{product.similarityStarScore}</td>
                                                <td className="border-gray-300 border">{product.recentStarScore}</td>
                                                <td className="border-gray-300 border">{product.rankDownScoreType}</td>
                                                <td className="border-gray-300 border">{product.reliabilityType}</td>
                                                <td
                                                    className="border-gray-300 border text-sm hover:cursor-pointer break-words p-2"
                                                    onClick={() => handleCopyToClipboard(product.nvmid)} // 클릭 시 클립보드에 복사
                                                >
                                                    {product.nvmid}
                                                </td>
                                            </tr>

                                        ))
                                    }
                                    </tbody>
                                    : null
                            }


                        </table>
                    </div>
                    :
                    null
            }
            {
                data && Object.keys(data).length === 0 ?
                    <div className="flex items-center justify-center w-full py-[10%]">
                        <p className="text-xl">검색 결과가 없습니다</p>
                    </div>
                    : null
            }
            <div>

            </div>
        </div>
    );
}

export default DataTable;
