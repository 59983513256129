// DaumPost.js
import React from 'react';
import DaumPostcode from 'react-daum-postcode';

const DaumPost = ({ onComplete }) => {
    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        onComplete(fullAddress); // 부모 컴포넌트로 주소 전달
    };

    return (
        <div >
            <DaumPostcode onComplete={handleComplete} />
        </div>
    );
};

export default DaumPost;
