import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MyChart from "./MyChart";
import sanitizeHtml from "sanitize-html";

const RankTrace = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [rankList, setRankList] = useState([]);
    const [userIdx, setUserIdx] = useState(""); // userIdx 상태 추가
    const navigate = useNavigate();
    const [inputValue1, setInputValue1] = useState(""); // 검색어
    const [inputValue2, setInputValue2] = useState(""); // nvmid
    const [keyword, setKeyword] = useState("");
    const [nvmid, setNvmid] = useState("");
    const [searchedData, setSearchedData] = useState(null); // 검색된 순위
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(null);
    const [rank, setRank] = useState(null);

    // keyword 또는 nvmid가 변경되면 API 호출
    useEffect(() => {
        if (keyword && nvmid) {
            getNaverShoppingRankByMidnow();
        }
    }, [keyword, nvmid]);
    // API 호출 함수
    const getNaverShoppingRankByMidnow = async () => {
        setLoading(true);
        setRank('');
        setSearchedData('');
        try {
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                setLoading(false);
                return;
            }

            // 먼저 searchCount와 maxSearch 값을 확인
            const countResponse = await axios.get(`https://weorthersback.shop/api/searchcount`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });
            console.log("jwt와 함께 요청 보냈음")
            const { searchcount, maxcount } = countResponse.data;

            // searchCount가 maxSearch를 넘는지 확인
            if (parseInt(searchcount) >= parseInt(maxcount)) {
                alert("검색 횟수가 최대치를 초과했습니다. 검색을 제한합니다.");
                setLoading(false);
                return; // maxSearch를 초과하면 검색을 중지
            }else{
                console.log("검색 가능합니다")
            }


            const response = await fetch("https://weorthersback.shop/nowsearch", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",  // JSON 형식으로 요청
                },
                body: JSON.stringify({
                    keyword: keyword,
                    nvmid: nvmid,
                }),
            });
            console.log(response);

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json(); // JSON 응답 파싱
            console.log(result);

            // 상태 업데이트
            setRank(result.rank); // rank 설정
            setTotal(result.total);
            setSearchedData(result.matchedItem); // 해당 객체 설정

            // rank가 0이고 matchedItem이 null이면 400위 초과 메시지 출력
            if (result.rank === 0 || result.matchedItem === null) {
                alert(result.message || "검색결과가 없습니다.");
            }else{
                // 검색된 결과가 있을 경우
                await axios.post('https://weorthersback.shop/increment', {}, {
                    headers: {
                        Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                    }
                });
                console.log("검색결과 있으므로 카운트 +1")
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("검색결과가 없습니다");
        } finally {
            setLoading(false);
        }
    };
    // 검색 버튼 클릭
    const handleSearch = () => {
        if (inputValue1 === "" || inputValue2 === ""){
            alert("키워드와 nvmid 모두 입력해주세요");
            return;
        }
        setKeyword(inputValue1);
        setNvmid(inputValue2);
    };
    const safeHtml = searchedData ? sanitizeHtml(searchedData.title, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a'],  // 허용할 태그 목록
        allowedAttributes: { a: ['href'] },  // 허용할 속성 설정
    }) : ''; // searchedData가 null이면 빈 문자열을 반환
    // Enter 키로 검색 실행
    const activeEnter = (e) => {
        if (e.key === "Enter") handleSearch();
    };
    // 검색 버튼 클릭
    const setTrace = () => {
        setTraceItem();
    };
    // useEffect에서 처음에 입력창을 띄우기
    useEffect(() => {
        // 입력창을 띄운 뒤 getCheckedList 호출
        getCheckedList();
    }, []);
    const setTraceItem = async () => {
        try{
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                setLoading(false);
                return;
            }
            const response1 = await axios.get(`https://weorthersback.shop/rank/list`, {
                headers: {
                    Authorization: `Bearer ${token}`, // JWT 토큰을 Authorization 헤더로 보내기
                },
            });

            // 받아온 데이터의 길이를 체크
            if (response1.data.length >= 10) {
                alert("10개까지만 등록이 가능합니다.");
                return; // 조건 만족 시 함수 종료
            }
            const response = await fetch("https://weorthersback.shop/rank/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",  // JSON 형식으로 요청
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    keyword: keyword,
                    nvmid: nvmid,
                    storeName : searchedData.mallName,
                    productName : searchedData.title
                }),
            });
            console.log(response);
            getCheckedList();
            alert("등록되었습니다.")
        }catch{
            alert("오류발생")
        }finally {
            console.log("일단 setTraceItem 실행됨")
        }

    }
    const getCheckedList = async () => {
        try {
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");

                return;
            }
            const response = await axios.get(`https://weorthersback.shop/rank/list`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });

            // 받아온 데이터를 rankList 상태에 저장
            setRankList(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching rank list:", error);
            // 에러 처리 (예: 로그인 페이지로 이동)
            navigate("/login");
        }
    };

    const handleInputChange = (event) => {
        setUserIdx(event.target.value); // 입력값을 userIdx 상태에 저장
    };

    const handleSubmit = () => {
        getCheckedList(); // userIdx가 입력되면 getCheckedList 호출
    };
    // 제목 클릭 시 상세 페이지로 이동
    const handleTitleClick = (id) => {
        navigate(`/ranktrace/${id}`); // id를 URL의 파라미터로 전달하여 상세 페이지로 이동
    };

    const goDelete = async (e, rankTrackingIdx, keyword, nvmid) => {
        e.preventDefault(); // 기본 동작 방지
        try {
            const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                return;
            }
            const data = {
                keyword: keyword,
                nvmid: nvmid,
                rankTrackingIdx: rankTrackingIdx
            };
            // POST 요청으로 변경
            const response = await axios.post(`https://weorthersback.shop/rank/delete`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json" // JSON 데이터 전송
                }
            });
            alert("삭제되었습니다.");
            console.log(response.data);
            getCheckedList(); // 업데이트 함수 호출
        } catch (error) {
            console.error("Error deleting rank:", error);
        }finally {
            getCheckedList();
        }
    };

    return (
        <div className="w-full flex justify-center items-center">
            <div className="w-[90%] ">
                <div className="flex justify-center pt-[30px] pb-[10px]">
                    <div className="sm:content-center relative">
                        <p className="text-[48px] py-3 font-bold">네이버 순위 추적</p>
                        <span className="text-[20px] text-yellow-400">실시간 검색</span>
                        <span className="text-[20px] text-gray-600">
                            의 버튼을 눌러 등록 가능합니다.
                        </span>
                        <p className="text-gray-400">등록한 품목은 매일 오전 11시 재검색되어 DB에 저장되며, 최대 10개까지 저장 가능합니다.</p>
                    </div>
                </div>
                <div className="flex justify-center space-x-0 pt-6 relative">
                    <input
                        className="pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[300px] h-[65px] text-lg"
                        value={inputValue1}
                        onChange={(e) => setInputValue1(e.target.value)}
                        placeholder="검색어를 입력하세요"
                        onKeyDown={activeEnter}
                    />
                    <input
                        className="pl-12 border-l border-t border-b border-gray-400 w-[300px] h-[65px] text-lg"
                        value={inputValue2}
                        onChange={(e) => setInputValue2(e.target.value)}
                        placeholder="nvmid를 입력하세요"
                        onKeyDown={activeEnter}
                    />
                    <button
                        className="bg-blue-500 rounded-r-md text-white p-2 w-[120px] h-[65px]"
                        onClick={handleSearch}
                        disabled={loading} // 로딩 중 버튼 비활성화
                    >
                        {loading ? "검색 중..." : "검색"}
                    </button>
                </div>
                <div className="flex justify-center pt-6">
                    {searchedData && (
                        <div>
                            <div>
                                <div className="flex justify-center pt-12 w-full">
                                    <div
                                        className="w-full flex justify-center border-2 border-gray-400 rounded-md bg-gray-100">
                                        <div className="p-12">
                                            <img
                                                src={searchedData.image}
                                                className="w-[300px] h-auto rounded-md"
                                                alt={searchedData?.image || "기본 이미지"}
                                            />
                                        </div>
                                        <div className="p-12 pt-6 w-full">
                                            <div className="text-left">
                                                <div className="flex">
                                                    <span
                                                        className="text-4xl  text-blue-500"> {rank}위</span>
                                                    <span className="text-4xl"> /{total}개</span>
                                                </div>
                                                {
                                                    searchedData.link ?
                                                        <p className="text-4xl py-6">
                                                            <a href={searchedData.link}
                                                               target="_blank">
                                                                <div dangerouslySetInnerHTML={{__html: safeHtml}}/>
                                                            </a>
                                                        </p>
                                                        :
                                                        <p className="text-4xl py-6">

                                                            <div dangerouslySetInnerHTML={{__html: safeHtml}}/>

                                                        </p>
                                                }
                                                <div className="flex">
                                                    <p className="text-3xl">판매처:
                                                        {searchedData.mallName}
                                                    </p>
                                                </div>
                                                <div className="flex space-x-10 pt-6">
                                                    <div>
                                                        <span className="text-[30px]">가격: </span>
                                                        <span
                                                            className="text-blue-500 text-[30px]">{searchedData.lprice}</span>
                                                        <span className="text-[30px]">원</span>
                                                        <button
                                                            className="bg-blue-500 p-2 rounded-md text-white w-auto h-12 mt-5 ml-12 hover:cursor-pointer"
                                                            onClick={() => {
                                                                setTrace()
                                                            }}>순위추적 등록
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {
                    rankList.length > 0 ? (
                        <div className="text-3xl py-4 text-left pl-[5%] flex">
                            <p className="mr-4">추적 등록 슬롯</p>
                            <p className="text-blue-500">{rankList.length}</p>
                            <p> / 10</p>
                        </div>
                    ) : (
                        <div className="text-3xl py-4 text-left pl-[5%] flex">
                            <p className="mr-4">추적 등록 슬롯</p>
                            <p>0 / 10</p>
                        </div>
                    )
                }

                {/* 순위 리스트. 클릭 시 스크롤이 위 div로 이동. 위 div 나타남. */}
                <div className="flex flex-col justify-center">
                    <div className="overflow-x-auto">
                        <table className="text-lg text-left w-full border-collapse">
                            <thead>
                            <tr>
                                <td className="border p-2">키워드</td>
                                <td className="border p-2">nvmid</td>
                                <td className="border p-2">상품명</td>
                                <td className="border p-2">판매점</td>
                                <td className="border p-2">등록일</td>
                                <td className="border p-2"></td>
                            </tr>
                            </thead>
                            <tbody>
                            {rankList.length > 0 ? (
                                rankList.map((rank, index) => {
                                    const formattedDate = new Date(rank.regdate).toISOString().split('T')[0];
                                    return (
                                        <tr key={index}>
                                            <td className="border p-2">{rank.keyword}</td>
                                            <td className="border p-2">{rank.nvmid}</td>
                                            <td className="border p-2 hover:text-blue-500 hover:cursor-pointer"
                                                onClick={() => handleTitleClick(rank.rankTrackingIdx
                                                )}>{rank.productName}</td>
                                            <td className="border p-2">{rank.storeName}</td>
                                            <td className="border p-2">{formattedDate}</td>
                                            <td className="border p-2">
                                                <button className="bg-red-600 text-white p-2 px-4 rounded-md"
                                                        onClick={(e) => goDelete(e, rank.rankTrackingIdx, rank.keyword, rank.nvmid)}>X
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center border p-2">순위 정보가 없습니다.</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankTrace;
