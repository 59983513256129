import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";

const OAuth = ({userinfo, setUserinfo, setAdmin}) => {
    const [loading, setLoading] = useState(true);  // 로딩 상태
    const [jwt, setJwt] = useState(""); // JWT를 저장할 상태
    const [data, setData] = useState(null);
    const code = new URL(window.location.href).searchParams.get("code"); // 인가 코드 추출
    console.log("추출된 code:", code);  // code가 제대로 추출되는지 확인
    const navigate = useNavigate();
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
    };

    useEffect(() => {
        setLoading(true);
        fetch(`https://weorthersback.shop/users/signup?code=${code}`, {
            method: "POST",
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("서버 응답 데이터:", data);  // 서버로부터 받은 응답 데이터

                setData(data);

                // `data.result.jwt` 값이 유효한지 확인
                if (data.result && data.result.jwt) {
                    console.log("JWT 토큰:", data.result.jwt);
                    setJwt(data.result.jwt);  // jwt 상태 설정
                } else {
                    console.log("JWT 토큰이 없음");
                }

                setUserinfo(data.result.user_info);
                console.log("Userinfo:", data.result.user_info);

                setLoading(false);

                // 로컬 스토리지에 JWT와 userinfo 저장
                localStorage.setItem("jwt", data.result.jwt);

                localStorage.setItem("userinfo", JSON.stringify(data.result.user_info));
                console.log("로컬스토리지 jwt:", localStorage.getItem("jwt"));  // 저장된 jwt 확인

                // 부모 창으로 메시지 보내기
                if (window.opener) {
                    window.opener.postMessage({
                        userInfo: data.result.user_info,
                        jwt: data.result.jwt,
                        go: "/"
                    }, "*");
                }
                const userDTO = data.result.userDTO;
                console.log(userDTO);
                if(userDTO.grade === "6"){
                    localStorage.setItem("admin", true);
                    setAdmin(true);
                }
                navigate("/");
                window.location.reload();
            })
            .catch((error) => {
                console.error("오류 발생", error);
                setLoading(false);
            });
    }, [code]);  // `code`가 변경될 때마다 실행

    return (
        <div>
            <p>Oauth1 page</p>
        </div>
    );
};

export default OAuth;
