import {useEffect, useState} from "react";

const MobileDataTable = ({data, setData}) => {

    // todo 옵션에 품질점수순도 추가

    const [forDate, setForDate] = useState('');  // 상태를 컴포넌트 내에서 정의

    const [searchType, setSearchType] = useState('A');
    // 각각 다르게 sort해서 넣을 state
    // 랭킹순
    const [best, setBest] = useState(null);
    // 클릭순
    const [click, setClick] = useState(null);
    // 판매순
    const [sell, setSell] = useState(null);
    // 금액순
    const [price, setPrice] = useState(null);
    // 품질점수순 qualityStarScore
    const [quality, setQuality] = useState(null);
    // Update sorting states when data changes

    useEffect(() => {
        if (data != null && Object.keys(data).length > 0) {

            const bestData = [...data.result.products].sort((a, b) => a.best - b.best);
            const clickData = [...data.result.products].sort((a, b) => b.hitStarScore - a.hitStarScore);
            const sellData = [...data.result.products].sort((a, b) => b.saleStarScore - a.saleStarScore);
            const priceData = [...data.result.products].sort((a, b) => b.lowPrice - a.lowPrice);
            const qualityData = [...data.result.products].sort((a, b) => b.qualityStarScore - a.qualityStarScore);

            setBest(bestData);

            setClick(clickData);

            setSell(sellData);

            setPrice(priceData);

            setQuality(qualityData);
        }
    }, [data]);  // This will run when `data` changes

    useEffect(() => {
        if (data != null && data.result) {
            const dateString = data.result.searchTime;  // searchTime을 가져옴
            const date = new Date(dateString);
            const formattedDate = date.toLocaleString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replace(',', '');  // 쉼표 제거

            setForDate(formattedDate);  // 상태 업데이트
        }
    }, [data]);


    // searchType 변경 처리 함수
    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
    };
    return(
        <div>
            <div>

                {
                    data  && Object.keys(data).length > 0 ?
                        <div className="flex justify-center py-4 ">
                            <div className="flex justify-center">
                                <p className=" text-sm text-left p-2">※ 데이터 기준 시간 : {forDate}</p>

                            </div>
                            <div className="flex content-end">
                                <select onChange={handleSearchTypeChange}
                                        className="rounded-md border border-gray-600 text-center content-end w-auto h-auto text-sm pl-2">
                                    <option value="A">랭킹순</option>
                                    <option value="B">클릭순</option>
                                    <option value="C">판매순</option>
                                    <option value="D">가격순</option>
                                    <option value="E">품질순</option>
                                </select>
                            </div>
                        </div>


                        : null
                }


                {
                    data  && Object.keys(data).length > 0 ?
                        <div className="w-full">
                            {
                                searchType === 'A' ?
                                    <div className="items-center w-[95%] mx-auto">

                                        {
                                            data.result.products.map((item, index) => (
                                                <div key={index}>
                                                    <div
                                                        className="relative flex flex-row my-6 bg-white shadow-sm border   w-full max-w-3xl text-left">

                                                        <div className="relative  w-1/3 overflow-hidden text-white  p-2 pt-3">
                                                            <img
                                                                src={item.imageUrl}
                                                                alt="card-image" className="w-auto h-auto"/>
                                                        </div>


                                                        <div className=" w-2/3">
                                                            <div className=" gap-1 ml-auto py-1">
                                                                <badge
                                                                    className="text-xs bg-blue-500 rounded-md p-1 text-white w-8">rank
                                                                    : <span>{item.rank}</span>
                                                                </badge>

                                                            </div>
                                                            <div className="flex items-center mb-1">

                                                                <p className="text-xs font-bold">
                                                                    {item.productTitle}
                                                                </p>


                                                            </div>

                                                            <p className="text-xs">
                                                                {
                                                                    item.mallCount === 0
                                                                        ? `판매처: ${item.mallName} `
                                                                        : `판매처: ${item.mallCount} 곳`
                                                                }
                                                            </p>
                                                            <p className="text-xs">
                                                                판매가: {item.lowPrice} 원
                                                            </p>
                                                            <div className="flex space-x-2">
                                                                <span className="text-xs">찜 수: {item.keepCnt}</span>
                                                                <span className="text-xs">리뷰 수: {item.reviewCount}</span>
                                                            </div>
                                                            <div>
                                                                <table
                                                                    className="text-[10px] space-x-1 my-1 bg-gray-100 text-center">
                                                                    <tr>
                                                                        <th className="border-l-gray-300 pr-1">클릭점수</th>
                                                                        <th className="border-l-gray-300 pr-1">판매점수</th>
                                                                        <th className="border-l-gray-300 pr-1">리뷰점수</th>
                                                                        <th className="border-l-gray-300 pr-1">품질점수</th>
                                                                        <th className="border-l-gray-300 pr-1">연관성</th>
                                                                        <th className="border-l-gray-300 pr-1">유사성</th>
                                                                    </tr>
                                                                    <tr className="font-bold">
                                                                        <td>{item.hitStarScore}</td>
                                                                        <td>{item.saleStarScore}</td>
                                                                        <td>{item.reviewCountStarScore}</td>
                                                                        <td>{item.qualityStarScore}</td>
                                                                        <td>{item.relevanceStarScore}</td>
                                                                        <td>{item.similarityStarScore}</td>

                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }


                                    </div>
                                    :null
                            }
                            {
                                searchType === 'B' ?
                                    <div className="items-center w-[95%] mx-auto">

                                        {
                                            click.map((item, j) => (
                                                <div key={j}>
                                                    <div
                                                        className="relative flex flex-row my-6 bg-white shadow-sm border   w-full max-w-3xl text-left">

                                                        <div className="relative  w-1/3 overflow-hidden text-white p-2 pt-3">
                                                            <img
                                                                src={item.imageUrl}
                                                                alt="card-image" className="w-auto h-auto"/>
                                                        </div>


                                                        <div className=" w-2/3">
                                                            <div className=" gap-1 ml-auto py-1">
                                                                <badge
                                                                    className="text-xs bg-blue-500 rounded-md p-1 text-white w-8">rank
                                                                    : <span>{item.rank}</span>
                                                                </badge>

                                                            </div>
                                                            <div className="flex items-center mb-1">

                                                                <p className="text-xs font-bold">
                                                                    {item.productTitle}
                                                                </p>


                                                            </div>

                                                            <p className="text-xs">
                                                                {
                                                                    item.mallCount === 0
                                                                        ? `판매처: ${item.mallName} `
                                                                        : `판매처: ${item.mallCount} 곳`
                                                                }
                                                            </p>
                                                            <p className="text-xs">
                                                                판매가: {item.lowPrice} 원
                                                            </p>
                                                            <div className="flex space-x-2">
                                                                <span className="text-xs">찜 수: {item.keepCnt}</span>
                                                                <span className="text-xs">리뷰 수: {item.reviewCount}</span>
                                                            </div>
                                                            <div>
                                                                <table
                                                                    className="text-[10px] space-x-1 my-1 bg-gray-100 text-center">
                                                                    <tr>
                                                                        <th className="border-l-gray-300 pr-1">클릭점수</th>
                                                                        <th className="border-l-gray-300 pr-1">판매점수</th>
                                                                        <th className="border-l-gray-300 pr-1">리뷰점수</th>
                                                                        <th className="border-l-gray-300 pr-1">품질점수</th>
                                                                        <th className="border-l-gray-300 pr-1">연관성</th>
                                                                        <th className="border-l-gray-300 pr-1">유사성</th>
                                                                    </tr>
                                                                    <tr className="font-bold">
                                                                        <td>{item.hitStarScore}</td>
                                                                        <td>{item.saleStarScore}</td>
                                                                        <td>{item.reviewCountStarScore}</td>
                                                                        <td>{item.qualityStarScore}</td>
                                                                        <td>{item.relevanceStarScore}</td>
                                                                        <td>{item.similarityStarScore}</td>

                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }


                                    </div>
                                    :null
                            }
                            {
                                searchType === 'C' ?
                                    <div className="items-center w-[95%] mx-auto">

                                        {
                                            sell.map((item, k) => (
                                                <div key={k}>
                                                    <div
                                                        className="relative flex flex-row my-6 bg-white shadow-sm border   w-full max-w-3xl text-left">

                                                        <div className="relative  w-1/3 overflow-hidden text-white p-2 pt-3">
                                                            <img
                                                                src={item.imageUrl}
                                                                alt="card-image" className="w-auto h-auto"/>
                                                        </div>


                                                        <div className=" w-2/3">
                                                            <div className=" gap-1 ml-auto py-1">
                                                                <badge
                                                                    className="text-xs bg-blue-500 rounded-md p-1 text-white w-8">rank
                                                                    : <span>{item.rank}</span>
                                                                </badge>

                                                            </div>
                                                            <div className="flex items-center mb-1">

                                                                <p className="text-xs font-bold">
                                                                    {item.productTitle}
                                                                </p>


                                                            </div>

                                                            <p className="text-xs">
                                                                {
                                                                    item.mallCount === 0
                                                                        ? `판매처: ${item.mallName} `
                                                                        : `판매처: ${item.mallCount} 곳`
                                                                }
                                                            </p>
                                                            <p className="text-xs">
                                                                판매가: {item.lowPrice} 원
                                                            </p>
                                                            <div className="flex space-x-2">
                                                                <span className="text-xs">찜 수: {item.keepCnt}</span>
                                                                <span className="text-xs">리뷰 수: {item.reviewCount}</span>
                                                            </div>
                                                            <div>
                                                                <table
                                                                    className="text-[10px] space-x-1 my-1 bg-gray-100 text-center">
                                                                    <tr>
                                                                        <th className="border-l-gray-300 pr-1">클릭점수</th>
                                                                        <th className="border-l-gray-300 pr-1">판매점수</th>
                                                                        <th className="border-l-gray-300 pr-1">리뷰점수</th>
                                                                        <th className="border-l-gray-300 pr-1">품질점수</th>
                                                                        <th className="border-l-gray-300 pr-1">연관성</th>
                                                                        <th className="border-l-gray-300 pr-1">유사성</th>
                                                                    </tr>
                                                                    <tr className="font-bold">
                                                                        <td>{item.hitStarScore}</td>
                                                                        <td>{item.saleStarScore}</td>
                                                                        <td>{item.reviewCountStarScore}</td>
                                                                        <td>{item.qualityStarScore}</td>
                                                                        <td>{item.relevanceStarScore}</td>
                                                                        <td>{item.similarityStarScore}</td>

                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }


                                    </div>
                                    :null
                            }
                            {
                                searchType === 'D' ?
                                    <div className="items-center w-[95%] mx-auto">

                                        {
                                            price.map((item, l) => (
                                                <div key={l}>
                                                    <div
                                                        className="relative flex flex-row my-6 bg-white shadow-sm border   w-full max-w-3xl text-left">

                                                        <div className="relative  w-1/3 overflow-hidden text-white p-2 pt-3">
                                                            <img
                                                                src={item.imageUrl}
                                                                alt="card-image" className="w-auto h-auto"/>
                                                        </div>


                                                        <div className=" w-2/3">
                                                            <div className=" gap-1 ml-auto py-1">
                                                                <badge
                                                                    className="text-xs bg-blue-500 rounded-md p-1 text-white w-8">rank
                                                                    : <span>{item.rank}</span>
                                                                </badge>

                                                            </div>
                                                            <div className="flex items-center mb-1">

                                                                <p className="text-xs font-bold">
                                                                    {item.productTitle}
                                                                </p>


                                                            </div>

                                                            <p className="text-xs">
                                                                {
                                                                    item.mallCount === 0
                                                                        ? `판매처: ${item.mallName} `
                                                                        : `판매처: ${item.mallCount} 곳`
                                                                }
                                                            </p>
                                                            <p className="text-xs">
                                                                판매가: {item.lowPrice} 원
                                                            </p>
                                                            <div className="flex space-x-2">
                                                                <span className="text-xs">찜 수: {item.keepCnt}</span>
                                                                <span className="text-xs">리뷰 수: {item.reviewCount}</span>
                                                            </div>
                                                            <div>
                                                                <table
                                                                    className="text-[10px] space-x-1 my-1 bg-gray-100 text-center">
                                                                    <tr>
                                                                        <th className="border-l-gray-300 pr-1">클릭점수</th>
                                                                        <th className="border-l-gray-300 pr-1">판매점수</th>
                                                                        <th className="border-l-gray-300 pr-1">리뷰점수</th>
                                                                        <th className="border-l-gray-300 pr-1">품질점수</th>
                                                                        <th className="border-l-gray-300 pr-1">연관성</th>
                                                                        <th className="border-l-gray-300 pr-1">유사성</th>
                                                                    </tr>
                                                                    <tr className="font-bold">
                                                                        <td>{item.hitStarScore}</td>
                                                                        <td>{item.saleStarScore}</td>
                                                                        <td>{item.reviewCountStarScore}</td>
                                                                        <td>{item.qualityStarScore}</td>
                                                                        <td>{item.relevanceStarScore}</td>
                                                                        <td>{item.similarityStarScore}</td>

                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }


                                    </div>
                                    :null
                            }
                            {
                                searchType === 'E' ?
                                    <div className="items-center w-[95%] mx-auto">

                                        {
                                            quality.map((item, m) => (
                                                <div key={m}>
                                                    <div
                                                        className="relative flex flex-row my-6 bg-white shadow-sm border   w-full max-w-3xl text-left">

                                                        <div className="relative  w-1/3 overflow-hidden text-white pt-2">
                                                            <img
                                                                src={item.imageUrl}
                                                                alt="card-image" className="w-auto h-auto"/>
                                                        </div>


                                                        <div className=" w-2/3">
                                                            <div className=" gap-1 ml-auto py-1">
                                                                <badge
                                                                    className="text-xs bg-blue-500 rounded-md p-1 text-white w-8">rank
                                                                    : <span>{item.rank}</span>
                                                                </badge>

                                                            </div>
                                                            <div className="flex items-center mb-1">

                                                                <p className="text-xs font-bold">
                                                                    {item.productTitle}
                                                                </p>


                                                            </div>

                                                            <p className="text-xs">
                                                                {
                                                                    item.mallCount === 0
                                                                        ? `판매처: ${item.mallName} `
                                                                        : `판매처: ${item.mallCount} 곳`
                                                                }
                                                            </p>
                                                            <p className="text-xs">
                                                                판매가: {item.lowPrice} 원
                                                            </p>
                                                            <div className="flex space-x-2">
                                                                <span className="text-xs">찜 수: {item.keepCnt}</span>
                                                                <span className="text-xs">리뷰 수: {item.reviewCount}</span>
                                                            </div>
                                                            <div>
                                                                <table
                                                                    className="text-[10px] space-x-1 my-1 bg-gray-100 text-center">
                                                                    <tr>
                                                                        <th className="border-l-gray-300 pr-1">클릭점수</th>
                                                                        <th className="border-l-gray-300 pr-1">판매점수</th>
                                                                        <th className="border-l-gray-300 pr-1">리뷰점수</th>
                                                                        <th className="border-l-gray-300 pr-1">품질점수</th>
                                                                        <th className="border-l-gray-300 pr-1">연관성</th>
                                                                        <th className="border-l-gray-300 pr-1">유사성</th>
                                                                    </tr>
                                                                    <tr className="font-bold">
                                                                        <td>{item.hitStarScore}</td>
                                                                        <td>{item.saleStarScore}</td>
                                                                        <td>{item.reviewCountStarScore}</td>
                                                                        <td>{item.qualityStarScore}</td>
                                                                        <td>{item.relevanceStarScore}</td>
                                                                        <td>{item.similarityStarScore}</td>

                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }


                                    </div>
                                    :null
                            }
                        </div>



                    : null
                }
                {
                    data && Object.keys(data).length === 0 ?
                        <div className="flex items-center justify-center w-full py-[10%]">
                            <p className="text-md">검색 결과가 없습니다</p>
                        </div>
                        :null
                }

            </div>
        </div>
    )
}
export default MobileDataTable;