import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import KakaoLogout from "./KakaoLogout";

const Navbar = ({ navType, setNavType, islogin, setIslogin, admin }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // 드롭다운 상태
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // 유저 메뉴 상태
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); // '지수검색' 드롭다운 상태

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);  // 드롭다운 열기/닫기
    };

    const toggleIndexDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible); // '지수검색' 드롭다운 열기/닫기
    };

    const GoLogout = () => {
        setIslogin(false);
        localStorage.clear();
        navigate("/");
    };

    const navItems = {
        A: {
            title: '네이버 쇼핑',
            options: [
                { label: 'nvmid 기준', path: '/naversearch1', type: 'B' },
                { label: '상품명/스토어명 기준', path: '/naversearch2', type: 'C' },
            ],
        },
        B: {
            title: '네이버 쇼핑',
            options: [
                { label: 'nvmid 기준', path: '/naversearch1', type: 'B' },
                { label: '상품명/스토어명 기준', path: '/naversearch2', type: 'C' },
            ],
        },
        C: {
            title: '네이버 쇼핑',
            options: [
                { label: 'nvmid 기준', path: '/naversearch1', type: 'B' },
                { label: '상품명/스토어명 기준', path: '/naversearch2', type: 'C' },
            ],
        },
    };

    useEffect(() => {
        // 여기에 필요한 효과를 추가할 수 있습니다.
    }, [islogin]);

    const currentNav = navItems[navType];

    return (
        <div>
            {currentNav && (
                <div className="flex bg-blue-500 justify-around items-center w-full space-x-3 py-2">
                    <div>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/위아더스로고1.png`}
                            className="h-20 hover:cursor-pointer"
                            onClick={() => {
                                navigate("/")
                            }}
                        />
                    </div>

                    <div className="flex space-x-12">
                        <div className="relative pt-3 ">
                            <button
                                onClick={() => {
                                    navigate("/notice")
                                }}
                                className="flex items-center space-x-2 hover:text-gray-300"
                            >
                                <span
                                    className="text-white text-[28px] mb-3 hover:cursor-pointer flex">공지사항</span>
                            </button>
                        </div>
                        <div className="relative">
                            <h1
                                className="text-white text-[28px] mt-3 hover:cursor-pointer flex"
                                onClick={() => {

                                    toggleIndexDropdown(); // '지수검색' 드롭다운 토글
                                }}
                            >
                                {currentNav.title}
                                <svg
                                    className="w-6 h-6 mt-2 ml-2"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </h1>

                            {/* 지수검색 드롭다운 */}
                            {isDropdownVisible && (
                                <div
                                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
                                <span
                                    onClick={() => {
                                        navigate("/");
                                        setNavType("A");
                                        setIsDropdownVisible(false);
                                        // 드롭다운을 닫고, 필요한 액션을 처리
                                    }}
                                    className="block px-4 py-2 hover:bg-gray-100"
                                >
                                  지수검색
                                </span>
                                    <span
                                        onClick={() => {
                                            navigate("/searchnow");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                            // 드롭다운을 닫고, 필요한 액션을 처리
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      실시간 순위(nvmid)
                                    </span>
                                    <span
                                        onClick={() => {
                                            navigate("/searchnow2");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                            // 드롭다운을 닫고, 필요한 액션을 처리
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      실시간 순위(마켓명)
                                    </span>
                                    <span
                                        onClick={() => {
                                            navigate("/ranktrace");
                                            setNavType("A");
                                            setIsDropdownVisible(false);
                                            // 드롭다운을 닫고, 필요한 액션을 처리
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      랭킹추적
                                    </span>
                                </div>
                            )}
                        </div>


                        <div className="relative pt-3 ">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-2 hover:text-gray-300"
                            >
                                {/*<img*/}
                                {/*    src={`${process.env.PUBLIC_URL}/assets/naverstore-logo.png`}*/}
                                {/*    className="h-8"*/}
                                {/*/>*/}
                                <span
                                    className="text-white text-[28px] mb-3 hover:cursor-pointer flex">네이버 플러스 스토어</span>
                                <svg
                                    className="w-6 h-6 mb-3"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>

                            {/* 드롭다운 옵션이 열릴 때 */}
                            {isOpen && (
                                <div
                                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
                                    {currentNav.options.map((option, index) => (
                                        <span
                                            key={index}
                                            onClick={() => {
                                                navigate(option.path);
                                                setNavType(option.type);
                                                setIsOpen(false); // 드롭다운 닫기
                                            }}
                                            className="block px-4 py-2 hover:bg-gray-100"
                                        >
                                          {option.label}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>


                    </div>

                    {islogin ? (
                        <div className="relative">
                            <div
                                className="flex items-center space-x-2 hover:cursor-pointer"
                                onClick={toggleUserMenu}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/유저 아이콘.png`}
                                    className="h-12"
                                    alt="유저 아이콘"
                                />
                                <p className="text-white">유저</p>
                            </div>

                            {/* 유저 메뉴 */}
                            {isUserMenuOpen && (
                                <div
                                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
                                  <span
                                      onClick={() => {
                                          setIsUserMenuOpen(false);
                                          navigate("/mypage");
                                      }}
                                      className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  >
                                    내 정보
                                  </span>
                                    <span
                                        onClick={() => {
                                            setIsUserMenuOpen(false);
                                            navigate("/mycall");
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                    문의하기
                                  </span>
                                    {localStorage.getItem("admin") === "true" ? (
                                        <span
                                            onClick={() => {
                                                setIsUserMenuOpen(false);
                                                navigate("/adminpage");
                                            }}
                                            className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                          관리자 페이지
                                        </span>
                                    ) : null}
                                    {localStorage.getItem("admin") === "true" ? (
                                        <span
                                            onClick={() => {
                                                setIsUserMenuOpen(false);
                                                navigate("/admin/callcenter");
                                            }}
                                            className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                          고객문의 응대
                                        </span>
                                    ) : null}
                                    <span
                                        onClick={() => {
                                            GoLogout();
                                            setIsUserMenuOpen(false);
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    >
                    <KakaoLogout/>
                  </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            className="hover:cursor-pointer"
                            onClick={() => {
                                navigate("/login");
                            }}
                        >
                            <div className="text-left pl-12">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/로그인아이콘.png`}
                                    className="h-12"
                                />
                                <p className="text-white">로그인</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Navbar;
