import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Notice = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    useEffect(() => {

        getNoticeList();
    }, []);

    // 공지사항 목록 가져오기
    const getNoticeList = () => {
        axios.get("https://weorthersback.shop/notice")
            .then(response => {
                const dtoList = response.data;  // 서버로부터 받은 데이터
                console.log(dtoList);  // 받아온 데이터를 콘솔에 출력
                setData(dtoList);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    };

    // 제목 클릭 시 상세 페이지로 이동
    const handleTitleClick = (id) => {
        navigate(`/notice/${id}`); // id를 URL의 파라미터로 전달하여 상세 페이지로 이동
    };

    return (
        <div className="w-full p-4">
            <div className="flex justify-center">
                <div className="w-[70%] mx-auto"> {/* 가로 80%와 가운데 정렬 */}
                    <h1 className="text-left text-4xl text-blue-500 mb-4 font-bold pl-6 py-6">공지사항</h1>
                    {
                        localStorage.getItem("admin") === "true" ?
                            <div className="text-right">
                                <button className="text-white p-2 rounded-md bg-blue-500"
                                        onClick={() => {
                                            navigate("/notice/register")
                                        }}>글쓰기
                                </button>
                            </div>
                            :
                            null

                    }

                    {
                        data.length > 0 ? (
                            <div className="relative overflow-x-auto">
                                <table
                                    className="w-full text-lg text-left rtl:text-right text-gray-500 dark:text-gray-400 text-center">
                                    <thead
                                        className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 w-1/6">NO.</th>
                                        {/* 1의 비율 */}
                                        <th scope="col" className="px-6 py-3 w-2/3">제목</th>
                                        {/* 3의 비율 */}
                                        <th scope="col" className="px-6 py-3 w-1/6">작성일</th>
                                        {/* 1의 비율 */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.reverse().map((item, i) => {
                                            const formattedDate = new Date(item.regdate).toLocaleDateString('en-CA');
                                            return (
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                                    key={i}>
                                                    <td className="px-6 py-4 w-1/6">{data.length - 1 - i}</td>
                                                    <td className="px-6 py-4 hover:text-blue-500 hover:cursor-pointer w-2/3"
                                                        onClick={() => handleTitleClick(item.noticeIdx)}>
                                                        {item.noticeTitle}
                                                    </td>
                                                    <td className="px-6 py-4 w-1/6">{formattedDate}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>

                        ) : null
                    }
                </div>

            </div>
        </div>
    );
};

export default Notice;
