import {Background, LoadingText} from '../Styles';
import Spinner from '../';


const Loading = () => {
    return (
        <div>
            <Background>

                <img src={`${process.env.PUBLIC_URL}/assets/Spin@1x-1.0s-200px-200px.gif`} alt="로딩중" width="5%" />

            </Background>

        </div>
    )
}
export default Loading