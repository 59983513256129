import {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import sampleData from "../Data/SampleData.json";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import NaverExcelDownload from "./NaverExelDownload";
import NaverSearchCount from "./NaverSearchCount";

const NaverSearch = ({navType,setNavType}) => {
    const [keyword, setKeyword] = useState("");
    const [nvmid, setNvmid] = useState("");
    const [inputValue1, setInputValue1] = useState(""); // 검색어
    const [inputValue2, setInputValue2] = useState(""); // nvmid
    const [loading, setLoading] = useState(false);
    const [test3, setTest3] = useState(null); // API 응답 데이터
    const [useExcel, setUseExcel] = useState(null);
    const [searchedData, setSearchedData] = useState(null); // 검색된 데이터
    const [searchedDataIndex, setSearchedDataIndex] = useState(null); // 검색된 데이터의 인덱스

    const navigate = useNavigate();
    // 검색 처리
    const handleSearch = () => {
        setKeyword(inputValue1);
        setNvmid(inputValue2);
    };

    const activeEnter = (e) => {
        if (e.key === "Enter") handleSearch();
    };

    const handleCopyToClipboard = (nvmid2) => {
        // nvmid 값을 클립보드에 복사
        navigator.clipboard.writeText(nvmid2)
        alert("클립보드에 nvmid가 복사되었습니다")
    };

    const isFirstRender = useRef(true); // 첫 렌더링인지 확인

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // 첫 렌더링 이후에는 false로 설정
            return;
        }

        if (keyword || nvmid) {
            fetchData();
        }
    }, [keyword, nvmid]);


    const fetchData = async () => {
        setLoading(true);
        try {
            // 1. 로그인 확인 및 searchCount 확인
            const isSearchAllowed = await checkSearchCount();
            if (!isSearchAllowed) {
                setLoading(false);
                return; // 검색이 허용되지 않으면 종료
            }
            setSearchedData(null);
            setSearchedDataIndex(null);
            // const url = `https://weorthersback.shop/api/${keyword}`;
            const url = `https://weorthersback.shop/api/${keyword}`;
            const response = await axios.get(url);
            const initialData = response.data;
            const uniqueData = [];

            // 필터링 작업 (중복 제거)
            initialData.data.data = initialData.data.data.filter(item => {
                if (!uniqueData.some(obj => obj.card.product.nvMid === item.card.product.nvMid)) {
                    uniqueData.push(item);
                    return true; // 새롭게 추가된 값은 필터링 결과에 포함
                }
                return false; // 이미 있는 값은 무시
            });
            console.log(uniqueData.length);

            // 상태 업데이트
            setTest3(initialData);
            setUseExcel(uniqueData);
            // 2. searchCount 증가
            await incrementSearchCount();
            // 특정 nvmid 검색
            const foundData = initialData.data.data.find(
                (item) => String(item.card.product.nvMid) === String(nvmid)
            );
            if (foundData) {
                setSearchedData(foundData);
                setSearchedDataIndex(
                    initialData.data.data.findIndex(
                        (item) => String(item.card.product.nvMid) === String(nvmid)
                    )
                );
            } else {
                alert("해당 nvmid를 찾을 수 없습니다.");
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };



    const checkSearchCount = async () => {
        const token = localStorage.getItem("jwt"); // 로컬 스토리지에서 JWT 토큰 가져오기
        if (!token) {
            alert("로그인된 상태가 아닙니다.");
            navigate("/login");
            return false; // 로그인 상태가 아니면 false 반환
        }

        try {
            // searchCount와 maxSearch 값을 확인
            const countResponse = await axios.get(`https://weorthersback.shop/naver/searchcount`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });

            const { searchcount, maxcount } = countResponse.data;

            // searchCount가 maxSearch를 넘는지 확인
            if (parseInt(searchcount) >= parseInt(maxcount)) {
                alert("검색 횟수가 최대치를 초과했습니다. 검색을 제한합니다.");
                return false; // maxSearch를 초과하면 false 반환
            }

            return true; // 제한을 넘지 않으면 true 반환
        } catch (error) {
            console.error("Error checking search count:", error);
            return false; // 에러 발생 시 false 반환
        }
    };

    const incrementSearchCount = async () => {
        const token = localStorage.getItem("jwt"); // 로컬 스토리지에서 JWT 토큰 가져오기
        if (!token) {
            alert("로그인된 상태가 아닙니다.");
            navigate("/login");
            return; // 로그인 상태가 아니면 바로 종료
        }

        try {
            // searchCount가 증가된 후 API 호출
            await axios.post('https://weorthersback.shop/naver/increment', {}, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });
        } catch (error) {
            console.error("Error incrementing search count:", error);
        }
    };



    useEffect(()=>{
        setNavType('B')
    },[])

    useEffect(() => {
        // keyword가 바뀔 때마다 이 안의 코드가 실행됩니다.
        console.log('Keyword changed:', keyword);
    }, [keyword, nvmid]); // 의존성 배열에 keyword 추가

    const ITEMS_PER_PAGE = 100; // 한 페이지에 보여줄 아이템 수
    const MAX_ITEMS = 400; // 최대 표시할 항목 수
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태

    // 현재 페이지 데이터
    const currentData = test3?.data?.data
        ?.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            Math.min(currentPage * ITEMS_PER_PAGE, Math.min(test3?.data?.data?.length, MAX_ITEMS)) // 최대 400개까지만 표시
        )
        .map(item => ({ ...item }));

    // 총 페이지 수 계산 (최대 400개까지만)
    const totalPages = Math.ceil(Math.min((test3?.data?.data?.length || 0), MAX_ITEMS) / ITEMS_PER_PAGE);



    return (
        <div>
            {loading ?<Loading></Loading>:null}
            <div>
                <div className="flex justify-center pt-[30px]">
                    <div className="sm:content-center relative">
                        <p className="text-[48px] py-3 font-bold">네이버 플러스 순위 검색</p>
                       <span className="text-[20px] text-yellow-400">네이버 쇼핑 플러스</span><span
                        className="text-[20px] text-gray-600">의 최대 400등 까지 조회 가능합니다.</span>
                    </div>
                </div>
                <div className="pt-6 mr-[23%]">
                    <NaverSearchCount test3={test3} keyword={keyword} nvmid={nvmid}></NaverSearchCount>
                </div>


                <div className="flex justify-center space-x-0 pt-6 relative">
                    <input
                        className=" pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[300px] h-[65px]  text-lg"
                        value={inputValue1}
                        onChange={(e) => setInputValue1(e.target.value)}
                        placeholder="검색어를 입력하세요"
                        onKeyDown={(e) => activeEnter(e)}/>
                    <input
                        className=" pl-12 border-l border-t border-b border-gray-400  w-[300px] h-[65px]  text-lg"
                        value={inputValue2}
                        onChange={(e) => setInputValue2(e.target.value)}
                        placeholder="nvmid를 입력하세요"
                        onKeyDown={(e) => activeEnter(e)}/>
                    <button className="bg-blue-500 rounded-r-md  text-white p-2 w-[120px] h-[65px]"
                            onClick={handleSearch}>검색
                    </button>
                </div>
            </div>

            {
                searchedData ? (
                    <div className="flex justify-center pt-12">

                        <div className="w-[70%] flex justify-center border-2 border-gray-400 rounded-md bg-gray-100">
                            <div className="p-12">
                                <img
                                    src={searchedData.card.product.images[0].imageUrl}
                                    className="w-[300px] h-[320px] rounded-md"
                                    alt={searchedData?.card.product?.productName || "기본 이미지"}
                                />
                            </div>
                            <div className="p-12 pt-6">
                                <div className="text-left">
                                    <div className="flex">

                                        <span className="text-[30px] text-blue-500"> {searchedDataIndex +1}위</span>
                                        <span className="text-[30px]"> /{test3.data.total}개</span>
                                    </div>


                                    {
                                        searchedData.card.product.productUrl.pcUrl ?
                                            <p className="text-[36px] py-2">
                                                <a href={searchedData.card.product.productUrl.pcUrl}
                                                   target="_blank">
                                                    {searchedData.card.product.productName}
                                                </a>
                                            </p>
                                            :
                                            <p className="text-[36px] py-2">

                                                    {searchedData.card.product.productName}

                                            </p>
                                    }


                                    <div className="flex">
                                        {
                                            searchedData.card.product.mallUrl.pcUrl ?
                                                <p className="text-[28px]">판매처:
                                                    <a href={searchedData.card.product.mallUrl.pcUrl} target="_blank">
                                                    {searchedData.card.product.mallName}
                                                    </a>
                                                </p>
                                                :
                                                <p className="text-[28px]">판매처:

                                                        {searchedData.card.product.mallName}

                                                </p>
                                        }


                                        {
                                            searchedData.card.product.판매자등급 ?
                                                <p>빅파워</p>
                                                : null
                                        }
                                        {
                                            searchedData.card.product.굿서비스 ?
                                                <p>굿서비스</p>
                                                : null
                                        }


                                    </div>
                                    <div className="flex space-x-10 pt-6">
                                        <div>
                                            <span className="text-[30px]">가격: </span>
                                            <span
                                                className="text-blue-500 text-[30px]">{searchedData.card.product.discountedSalePrice}</span>
                                            <span className="text-[30px]">원</span>
                                        </div>
                                        <div className="text-[26px]">
                                            <span>평점: </span>
                                            <span
                                                className="text-blue-500 text-[28px]">{searchedData.card.product.averageReviewScore}</span>
                                            <span>점</span>
                                        </div>
                                        {/*<div className="text-[26px]">*/}
                                        {/*    <span>찜: </span>*/}
                                        {/*    <span*/}
                                        {/*        className="text-blue-500 text-[28px]">{searchedData.card.product.averageReviewScore}</span>*/}
                                        {/*    <span>개</span>*/}
                                        {/*</div>*/}
                                        <div className="text-[26px]">
                                            <span>리뷰: </span>
                                            <span
                                                className="text-blue-500 text-[28px]">{searchedData.card.product.totalReviewCount}</span>
                                            <span>개</span>
                                        </div>
                                        {/*<div className="text-[26px]">*/}
                                        {/*    <span>구매건수: </span>*/}
                                        {/*    <span*/}
                                        {/*        className="text-blue-500 text-[28px]">{searchedData.card.product.averageReviewScore}</span>*/}
                                        {/*    <span>건</span>*/}
                                        {/*</div>*/}

                                    </div>
                                    <div className="flex">
                                        {/*<p>카테고리</p>*/}
                                        <div className="flex">
                                            <button className="bg-blue-500 text-white flex items-center rounded-md p-2 mr-2"
                                                    onClick={() => handleCopyToClipboard(searchedData.card.product.nvMid)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-copy">
                                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                    <path
                                                        d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                </svg>
                                                <span className="ml-2">nvmid</span>
                                            </button>

                                        </div>
                                        <div className="flex">
                                            <button
                                                className="bg-blue-500 text-white flex items-center rounded-md p-2 mr-2"
                                                onClick={() => {
                                                    window.open(`https://search.shopping.naver.com/search/all?query=${keyword}&cat_id=&frm=NVSHATC&pagingIndex=1&pagingSize=80`, '_blank');
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-eye">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                <span className="ml-2">쇼핑URL</span>
                                            </button>

                                        </div>

                                    </div>
                                    {/*<div className="flex">*/}
                                    {/*    <p>상품링크</p>*/}
                                    {/*    <p>상품페이지</p>*/}
                                    {/*    <p>즐겨찾기</p>*/}
                                    {/*</div>*/}
                                </div>

                            </div>

                        </div>
                    </div>

                ) : null
            }

            {
                currentData ?
                    <div className="flex justify-between items-center w-[90%] pl-[10%]">
                        <div className="flex space-x-2 mt-4">
                            {Array.from({length: totalPages}, (_, i) => (
                                <button
                                    key={i}
                                    onClick={() => setCurrentPage(i + 1)}
                                    className={`p-2 ${
                                        currentPage === i + 1
                                            ? "bg-blue-500 text-white"
                                            : "bg-gray-300"
                                    }`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <p className="text-left pt-4 text-gray-500 text-sm">※한 페이지당 갯수는 100개입니다.</p>
                        </div>

                        <div className="flex justify-end mt-4 pl-[10%]">
                            <NaverExcelDownload useExcel={useExcel} keyword={keyword}></NaverExcelDownload>
                        </div>
                    </div>

                    : null
            }

            {
                currentData ?
                    <div className="flex justify-center pt-3">

                        <table className="table-auto w-[80%]">
                            <thead>
                            <tr className="bg-blue-500 text-white py-2 px-4 text-xl py-2 px-4 sticky top-0 z-10 ">
                                <td className="border-l-gray-300 py-2 text-xl w-1/12">순위</td>
                                <td className="border-l-gray-300 py-2 text-xl w-2/12">상품이미지</td>
                                <td className="border-l-gray-300 py-2 text-xl w-2/12">제품명</td>
                                <td className="border-l-gray-300 py-2 text-xl w-2/12">스토어명</td>
                                <td className="border-l-gray-300 py-2 text-xl w-2/12">가격</td>
                                <td className="border-l-gray-300 py-2 text-xl w-1/12">nvmid</td>
                                <td className="border-l-gray-300 py-2 text-xl w-1/12">평점</td>
                                <td className="py-2 text-xl w-1/12">리뷰</td>
                            </tr>
                            </thead>
                            <tbody>
                            {currentData.map((item, i) => {
                                const startRank = (currentPage - 1) * ITEMS_PER_PAGE;
                                const isSearched = searchedDataIndex === startRank + i;
                                return ( // 여기서 return을 추가해야 합니다.

                                    <tr key={i}
                                        className={`border-b border-gray-200 text-lg py-2 px-4 ${isSearched ? 'bg-gray-200' : ''}`}
                                    >
                                        {
                                            searchedDataIndex === startRank + i ? (
                                                <td className="border-l-gray-300 py-2 text-lg font-bold text-red-600 flex justify-center items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-check-circle mr-2 mt-12"
                                                    >
                                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                    </svg>
                                                    <span className="mt-12">{startRank + i + 1}</span>
                                                </td>
                                            ) : (
                                                <td className="border-l-gray-300 py-2 text-lg ">
                                                    {startRank + i + 1}
                                                </td>
                                            )
                                        }


                                        {/*이미지*/}
                                        <td className="border-l-gray-300 py-2 text-lg">
                                            <img
                                                src={item.card.product.images[0].imageUrl}
                                                className="w-[140px] h-auto rounded-md"
                                                alt={item?.card.product?.productName || "기본 이미지"}
                                            />
                                        </td>
                                        {/*제품명*/}
                                        <td className="border-l-gray-300 py-2 text-lg">{item.card.product.productName}</td>
                                        {/*스토어명*/}
                                        <td className="border-l-gray-300 py-2 text-lg">{item.card.product.mallName}</td>
                                        {/*가격*/}
                                        <td className="border-l-gray-300 py-2 text-lg">{item.card.product.discountedSalePrice.toLocaleString()}원</td>
                                        {/*nvmid*/}
                                        <td className="border-l-gray-300 py-2 text-lg flex items-center mt-[35%] gap-2">
                                            {item.card.product.nvMid}
                                            <button
                                                className="bg-blue-500 text-white flex items-center rounded-md p-2 mr-2"
                                                onClick={() => handleCopyToClipboard(item.card.product.nvMid)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                     viewBox="0 0 24 24" fill="none"
                                                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                     strokeLinejoin="round" className="feather feather-copy">
                                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                                    <path
                                                        d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                                </svg>
                                            </button>
                                        </td>
                                        {/*평점*/}
                                        <td className="border-l-gray-300 py-2 text-lg">{item.card.product.averageReviewScore}</td>
                                        {/*리뷰*/}
                                        <td className="border-l-gray-300 py-2 text-lg">{item.card.product.totalReviewCount}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    : null
            }


        </div>
    )
}
export default NaverSearch