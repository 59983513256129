import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // useNavigate 추가

const Mycall = () => {
    const [isData, setIsData] = useState(true);
    const [data, setData] = useState();
    const navigate = useNavigate(); // useNavigate 훅 사용

    useEffect(() => {
        // 비동기 함수 정의
        const fetchData = async () => {
            const url = `https://weorthersback.shop/callcenter/mycall`;
            const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기
            console.log(token)
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                navigate("/login");
                return;
            }

            try {
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Authorization 헤더에 토큰 추가
                    },
                });
                // 응답 데이터 처리
                console.log(response.data);
                setData(response.data);
            } catch (error) {
                console.error("데이터 가져오기 실패", error);
                alert("데이터를 가져오는 데 실패했습니다.");
            }
        };

        // fetchData 호출
        fetchData();
    }, [navigate]); // 빈 배열 대신 navigate를 의존성 배열에 추가

    const handleTitleClick = (id) => {
        navigate(`/callcenter/${id}`); // id를 URL의 파라미터로 전달하여 상세 페이지로 이동
    };

    return (
        <div>

            <div>
                {data && data.length > 0 ? (
                    <div className="w-11/12 mx-auto">
                        <table className="w-full table-fixed border-collapse">
                            <thead>
                            <tr className="border-b">
                                <td className="px-4 py-2">번호</td>
                                <td className="px-4 py-2">제목</td>
                                <td className="px-4 py-2">글쓴이</td>
                                <td className="px-4 py-2">카테고리</td>
                                <td className="px-4 py-2">등록일</td>
                                <td className="px-4 py-2">상태</td>
                            </tr>
                            </thead>
                            <tbody className="max-h-48 overflow-y-auto">
                            {data.map((item, i) => {
                                const formattedDate = new Date(item.regdate).toLocaleDateString("en-CA"); // YYYY-MM-DD 형식으로 변환
                                return (
                                    <tr className="border-b" key={i}>
                                        <td className="px-4 py-2">{item.callcenterIdx}</td>
                                        <td
                                            className="px-4 py-2 cursor-pointer text-blue-500"
                                            onClick={() => handleTitleClick(item.callcenterIdx)} // 제목 클릭 시 이동
                                        >
                                            {item.title}
                                        </td>
                                        <td className="px-4 py-2">{item.writer}</td>
                                        <td className="px-4 py-2">{item.category}</td>
                                        <td className="px-4 py-2">{formattedDate}</td>
                                        <td className="px-4 py-2">
                                            {item.complete === "Y" ? "완료" : "미완료"}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="w-11/12 mx-auto text-center p-[10%]">
                        <p>문의한 내역이 없습니다.</p>
                        <div className="flex justify-center">
                            <button className="text-white text-lg bg-blue-500 p-2 rounded my-12"
                            onClick={()=>{navigate("/callcenter/register")}}>문의하기</button>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};

export default Mycall;
