import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios'; // axios import
import DaumPost from "./DaumPost";
import Agreement from "./Agreement";
import KakaoLoginButton from "./KakaoLoginButton";


    const SignupPage = ({userinfo,setUserinfo}) => {
        const navigate = useNavigate();

        const [arg1, setAgr1] = useState(false);
        const [arg2, setAgr2] = useState(false);

        const [argmodal, setArgmodal] = useState(false);
        const [agr1chk, setAgr1chk] = useState(false);
        const [agr2chk, setAgr2chk] = useState(false);
        const [agr3chk, setAgr3chk] = useState(false);
        const [agrAllchk, setAgrAllchk] = useState(false);

        const [mkt1, setMkt1] = useState(false);
        const [mkt2, setMkt2] = useState(false);
        const [mkt3, setMkt3] = useState(false);
        const [mktAll, setMktAll] = useState(false);
        const [notMkt, setNotMkt] = useState(false);

        const [searchAdr, setSearchAdr] = useState(null);
        const [searchModal, setSearchModal] = useState(false);
        const [아이디체크,set아이디체크] = useState(false);

        //인증 후 저장할 userinfo의 state
        const [username, setUsername] = useState("");
        const [userEmail, setUserEmail] = useState("");


        // 주소 검색 완료 시 호출되는 함수
        const handleAddressComplete = (fullAddress) => {
            setSearchAdr(fullAddress); // 주소 값 저장
            setSearchModal(false); // 모달 닫기
        };


        const AgreeCheckboxAll = (e) => {
            const isChecked = e.target.checked;
            setAgrAllchk(isChecked);
            setAgr1chk(isChecked);
            setAgr2chk(isChecked);

        }
        const MktCheckboxAll = (e) => {
            const isChecked = e.target.checked;
            setMktAll(isChecked);
            setMkt1(isChecked);
            setMkt2(isChecked);
            setMkt3(isChecked);
            setNotMkt(!isChecked)
        }
        const NotMktChk = (e) => {
            const isChecked = e.target.checked;
            setMktAll(!isChecked);
            setMkt1(!isChecked);
            setMkt2(!isChecked);
            setMkt3(!isChecked);
            setNotMkt(isChecked);
        }

        const username1 = useRef(null);
        const userid = useRef(null);
        const userpw = useRef(null);
        const userpw2 = useRef(null);
        const userphone1 = useRef(null);
        const userphone2 = useRef(null);
        const userphone3 = useRef(null);
        const useremail1 = useRef(null);
        const useremail2 = useRef(null);

        const useraddress = useRef(null);
        const uesrdetailaddress = useRef(null);
        const userOrganization = useRef(null);
        const mktemail = useRef();
        const mktsms = useRef();
        const mktadr = useRef();


        const goSignUp = () => {
            const password = userpw.current.value;
            const useremail = useremail1.current.value;
            const userphone = userphone1.current.value + userphone2.current.value + userphone3.current.value;
            const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{10,16}$/;
            const data = {
                userName: username1.current.value,
                userId: userid.current.value,
                userPassword: userpw.current.value,
                userPhone: userphone,
                userEmail: useremail,
                userAddress: searchAdr,
                userDetailaddress: uesrdetailaddress.current.value,
                useYn : 'N',
                organization : userOrganization.current.value,
                mktEmail: mkt1 ? 'Y' : 'N',
                mktSms: mkt2 ? 'Y' : 'N',
                mktAdr: mkt3 ? 'Y' : 'N'
            };
            //1.아이디 중복체크되었는가 ?
            if(아이디체크===false){
                alert("아이디 중복체크를 해주세요.")
                return
            }
            //2.약관동의했는가?
            if(!agr1chk || !agr2chk){
                alert("약관동의를 마쳐주세요.")
                return
            }
            //3.빈칸 다 채웠는가?
            for (let key in data) {
                // mktEmail, mktSms, mktAdr는 빈칸이어도 되므로 이들을 제외한 필드만 체크
                if (key !== 'mktEmail' && key !== 'mktSms' && key !== 'mktAdr' && (data[key] === "" || data[key] === null)) {
                    alert("필수 입력값을 넣어주세요.");
                    return;
                }
            }
            //4.비밀번호와 비밀번호 재입력은 일치하는가?
            if (userpw.current.value!=userpw2.current.value){
                alert("비밀번호가 일치하지 않습니다")
                return
            }

            //5.비밀번호가 영문과 숫자 조합이 아닌가?
            if (!passwordPattern.test(password)) {
                alert("비밀번호는 영문과 숫자가 조합된 10자 이상 16자 이하여야 합니다.");
                return;
            }

            postData(data);
            setUserinfo(null);

        }



        const GoIdcheck = () => {
            const id = userid.current.value;
            if(id.length<6){
                alert("아이디가 너무 짧습니다.")
            }
            if(id.length>12){
                alert("아이디가 너무 깁니다.")
            }

            postId(id);
            clearLocalStorage();
        }

        const postData = async (data) => {

            try {
                const response = await axios.post('https://weorthersback.shop/users', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                // 성공적인 응답 처리
                alert("회원가입되었습니다.")
                navigate("/login")

            } catch (error) {
                // 에러가 발생했을 때 처리
                console.error('요청 실패:', error);
                // 에러 응답에서 상태 코드 확인
                if (error.response) {
                    console.error('에러 응답 데이터:', error.response.data);
                    alert(`회원가입 실패: ${error.response.data.message || "알 수 없는 오류 발생"}`);
                } else {
                    alert("회원가입 실패: 서버에 연결할 수 없습니다.");
                }
            }
        };
        const postId = async (id) => {
            try {

                const response = await axios.post('https://weorthersback.shop/users/idsearch',
                    { id: id },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                const result = response.data;
                // 응답을 확인

                if (result === 1) {

                    alert("이미 등록된 ID입니다.");
                } else if (result === 2) {
                    set아이디체크(true);
                    userid.current.readOnly = true;
                    alert("사용 가능한 ID입니다.");
                }

            } catch (error) {
                console.error('요청 실패:', error);
                if (error.response) {
                    // 서버가 반환한 에러 메시지를 표시
                    alert("서버 오류: " + error.response.data);
                } else {
                    alert("알 수 없는 오류 발생");
                }
            }
        };
        const [isFirstRender, setIsFirstRender] = useState(true);
        useEffect(() => {
            // 첫 렌더링이면 실행되지 않도록 처리
            if (isFirstRender) {
                setIsFirstRender(false); // 첫 렌더링 후에는 다음 렌더링부터 조건 적용
                return;
            }

            // userinfo가 null이 아니고, kakao_account와 profile이 존재할 때만 실행
            if (userinfo && userinfo.kakao_account && userinfo.kakao_account.profile) {
                console.log(userinfo.kakao_account.profile.nickname);
                setUsername(userinfo.kakao_account.profile.nickname);
                console.log(userinfo.kakao_account.email);
                setUserEmail(userinfo.kakao_account.email);
                clearLocalStorage();

            } else {
                clearLocalStorage();

            }
        }, [userinfo]); // userinfo가 변경될 때마다 실행

        useEffect(() => {
            const handleMessage = (event) => {
                // 메시지가 들어오면 처리
                if (event.data?.alertMessage) {
                    // 에러 메시지가 있으면 alert 띄우기
                    alert("이미 회원가입 된 회원입니다.");
                } else if (event.data?.userInfo) {
                    // userInfo가 있으면 처리

                    // 사용자 정보를 저장하거나 필요한 작업 수행
                    setUserinfo(event.data.userInfo); // setUserinfo 함수 호출
                }
            };

            window.addEventListener("message", handleMessage);

            // cleanup
            return () => window.removeEventListener("message", handleMessage);
        }, []);




        // 로컬스토리지를 초기화하는 함수
        const clearLocalStorage = () => {
            localStorage.removeItem('userinfo');  // 'userinfo'만 삭제
            localStorage.removeItem('jwt');

        };

        return (
            <div>
                <div>{
                    argmodal ? <Agreement arg1={arg1} setAgr1={setAgr1} arg2={arg2} setAgr2={setAgr2}
                                          setArgmodal={setArgmodal}></Agreement> : null
                }

                    {
                        searchModal ?
                            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                <div className="bg-white p-5 rounded shadow-lg w-[70%]">
                                    <DaumPost onComplete={handleAddressComplete}/>
                                    <button className="mt-3 bg-red-600 text-white p-2 rounded" onClick={(e) => {
                                        setSearchModal(false);
                                        e.preventDefault()
                                    }}>
                                        닫기
                                    </button>
                                </div>
                            </div>

                            : null
                    }
                </div>

                <div>
                    <div className="w-[100%] min-h-screen flex flex-col items-center pl-[20%]">
                        <div className="w-full max-w-screen-lg flex mt-10 bg-white rounded-md">

                            <div className="">
                                <h1 className="text-2xl font-bold mb-5 text-left">
                                    &gt; 회원 가입
                                </h1>
                                <hr className="border-2 border-black mb-5"></hr>
                                <h1 className="text-xl font-bold text-left mb-5">1. 약관 동의</h1>
                                <hr className="border-black mb-5 mt-0"/>
                                <div className="text-left">
                                    <input type="checkbox" id="all"
                                           checked={agrAllchk}
                                           onChange={AgreeCheckboxAll}></input>
                                    <label className="font-bold" for="all">모든 약관에 동의합니다</label>
                                    <hr className="border-gray-600 my-3"/>
                                </div>
                                <div className="text-left py-1 ">
                                    <div className="flex justify-between pl-2">
                                        <div>
                                            <input type="checkbox" id="agr1"
                                                   checked={agr1chk}
                                                   onChange={(e) => setAgr1chk(e.target.checked)}></input>
                                            <label className="text-gray-600" for="agr1">서비스 이용약관 동의 (필수)</label>
                                        </div>
                                        <button onClick={() => {
                                            setAgr1(true);
                                            setAgr2(false);

                                            setArgmodal(true)
                                        }}
                                                className="bg-gray-500 border-black text-white rounded-lg text-right text-sm p-1">약관
                                            전체보기
                                        </button>
                                    </div>
                                </div>
                                <div className="text-left py-1">
                                    <div className="flex justify-between pl-2">
                                        <div>
                                            <input type="checkbox" id="agr2"
                                                   checked={agr2chk}
                                                   onChange={(e) => setAgr2chk(e.target.checked)}></input>
                                            <label className="text-gray-600" for="agr2">개인정보처리방침_개인정보 수집 및 이용안내 동의
                                                (필수)</label>
                                        </div>
                                        <button onClick={() => {
                                            setAgr1(false);
                                            setAgr2(true);

                                            setArgmodal(true)
                                        }}
                                                className="bg-gray-500 border-black text-white rounded-lg text-right text-sm p-1">약관
                                            전체보기
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <h1 className="text-xl font-bold text-left mb-5">2. 본인인증</h1>
                                    <hr className="border-black mb-5 mt-0"/>
                                    <div className="flex justify-center">
                                        <div className="">
                                            <KakaoLoginButton> </KakaoLoginButton>
                                        </div>
                                    </div>

                                </div>

                                <div className="text-left pt-6">
                                    <h1 className="text-xl font-bold text-left mb-5">3. 정보입력{'('}필수입력사항{')'}</h1>
                                    <hr className="border-black mb-5 mt-0"/>
                                    <div className="">
                                        <form>
                                            <table>
                                                <tr>
                                                    이름 <span className="text-gray-500">(인증 시 자동입력)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500 bg-gray-100 p-1 w-[310px] h-[35px] text-sm"
                                                            type="text" placeholder="회원이름" title="회원이름"
                                                            value={username} // 상태로부터 값 설정
                                                            ref={username1} readonly></input>
                                                    </td>
                                                </tr>
                                            </table>
                                            <p></p>
                                            <table className="mt-10">
                                                <tr>
                                                    ID<span className="text-gray-500">(필수)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500  p-1 w-[310px] h-[35px] text-sm"
                                                            type="text" placeholder="아이디 입력, 6-12자리로 아이디 생성"
                                                            title="아이디 입력" ref={userid}>

                                                        </input>
                                                    </td>
                                                    <td>
                                                        <button className="rounded-md bg-gray-600 text-white p-2"
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // 기본 동작 방지
                                                                    GoIdcheck();
                                                                    // 모달 열기
                                                                }}>
                                                            중복체크
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                            <div>
                                                <p></p>
                                            </div>
                                            <table className="mt-10">
                                                <tr>
                                                    비밀번호<span className="text-gray-500">(필수)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500  p-1 w-[310px] h-[35px] text-sm"
                                                            type="password" placeholder="비밀번호 입력, 10-16자리 영문, 숫자 조합"
                                                            title="비밀번호 입력" ref={userpw}></input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500  p-1 w-[310px] h-[35px] text-sm"
                                                            type="password" placeholder="비밀번호 재입력" title="비밀번호 재입력"
                                                            ref={userpw2}></input>
                                                    </td>
                                                </tr>
                                            </table>
                                            <div className="mt-10">
                                                <p></p>
                                            </div>
                                            <table>
                                                <tr>
                                                    전화번호<span className="text-gray-500">(인증 시 자동입력)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <select
                                                            className="border border-gray-500  p-1 w-[200px] h-[35px] text-sm"
                                                            title="연락처 앞자리 선택" ref={userphone1}>
                                                            <option defaultValue value="010">010</option>
                                                            <option value="011">011</option>
                                                            <option value="017">017</option>
                                                            <option value="018">018</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500 p-1 w-[200px] h-[35px] text-sm"
                                                            type="text"
                                                            title="연락처 앞자리 입력"
                                                            ref={userphone2}
                                                            maxLength="4"
                                                            onChange={(e) => {
                                                                // 입력값이 4자를 초과하면, 초과된 부분을 자른다.
                                                                if (e.target.value.length > 4) {
                                                                    e.target.value = e.target.value.slice(0, 4); // 앞의 4자리만 남기고 나머지 자르기
                                                                }
                                                            }}
                                                        />

                                                    </td>
                                                    <td>
                                                        <input
                                                            className="border border-gray-500  p-1 w-[200px] h-[35px] text-sm"
                                                            type="text" title="연락처 뒷자리 입력" ref={userphone3}
                                                            maxLength="4"
                                                            onChange={(e) => {
                                                                // 입력값이 4자를 초과하면, 초과된 부분을 자른다.
                                                                if (e.target.value.length > 4) {
                                                                    e.target.value = e.target.value.slice(0, 4); // 앞의 4자리만 남기고 나머지 자르기
                                                                }
                                                            }}></input>
                                                    </td>
                                                </tr>
                                            </table>
                                            <p></p>
                                            <table className="mt-10">
                                                <tr>
                                                    메일<span className="text-gray-500">(인증 시 자동입력)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="text"
                                                               className="border border-gray-500  p-1 w-[400px] h-[35px] text-sm bg-gray-100"
                                                               value={userEmail}
                                                               title="이메일 앞자리 입력" ref={useremail1}/>
                                                    </td>

                                                </tr>
                                            </table>
                                            <div className="mt-10">
                                                <p></p>
                                            </div>
                                            <table>
                                                <tr>
                                                    주소<span className="text-gray-500">(필수)</span>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="text"
                                                               className="border border-gray-500  p-1 w-[350px] h-[35px] text-sm"
                                                               title="주소 입력" value={searchAdr} readOnly/>
                                                    </td>

                                                    <td>
                                                        <button className="rounded-md bg-gray-600 text-white p-2"
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // 기본 동작 방지
                                                                    setSearchModal(true); // 모달 열기
                                                                }}>
                                                            주소찾기 &gt;
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="text"
                                                               className="border border-gray-500  p-1 w-[350px] h-[35px] text-sm"
                                                               title="상세주소 입력" ref={uesrdetailaddress}/>
                                                    </td>
                                                </tr>
                                            </table>
                                            <ul className="list-disc text-sm text-gray-500">
                                                <li>입력하신 주소는 기본 배송지로 설정됩니다.</li>
                                            </ul>
                                            <p></p>
                                            <table className="mt-10">
                                                <tr>
                                                    소속회사<span className="text-gray-500">(선택)</span>
                                                </tr>
                                                <tr>
                                                    <td><input type="text"
                                                               className="border border-gray-500  p-1 w-[350px] h-[35px] text-sm"
                                                               title="소속회사 입력" ref={userOrganization}/></td>
                                                </tr>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                                <div className="text-left pt-10">
                                    <h3 className="font-bold text-[20px]">마케팅 활용 동의{'('}선택{')'}</h3>
                                    <p className="text-[13px] pb-6">마케팅/홍보를 위해 아래 항목의 정보를 이용하는데 동의하십니까? 동의 거부시 할인 및 이벤트
                                        정보안내
                                        서비스가 제한됩니다.</p>

                                    <table
                                        className="w-full border border-gray-300 text-center border-t-black border-t-2">
                                        <tbody>
                                        <tr>
                                            <td className="border border-gray-300 p-2" rowSpan="2">
                                                <p className="mb-2 font-bold">동의함</p>
                                                <input type="checkbox" id="mktAll"
                                                       checked={mktAll}
                                                       onChange={MktCheckboxAll}/>
                                                <label className="font-bold text-sm" for="mktAll">전체 동의</label>
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                이메일
                                            </td>
                                            <td className="border border-gray-300 p-2 ">
                                                SMS
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                주소(전단)
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                동의하지 않음
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 p-2">
                                                <input type="checkbox" id="mkt1"
                                                       checked={mkt1}
                                                       onChange={(e) => setMkt1(e.target.checked)} ref={mktadr}/>
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                <input type="checkbox" id="mkt2"
                                                       checked={mkt2}
                                                       onChange={(e) => setMkt2(e.target.checked)} ref={mktsms}/>
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                <input type="checkbox" id="mkt3"
                                                       checked={mkt3}
                                                       onChange={(e) => setMkt3(e.target.checked)} ref={mktadr}/>
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                <input type="checkbox" id="notMkt"
                                                       checked={notMkt}
                                                       onChange={NotMktChk}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className="flex justify-center space-x-3 pt-10 pb-12">
                                    <button className="bg-red-600 text-white p-2 rounded-sm w-[100px]" onClick={() => {
                                        goSignUp()
                                    }}>회원가입 &gt;</button>
                                    <button className="bg-gray-500 text-white p-2 rounded-sm w-[100px]" onClick={() => {
                                        navigate("/")
                                    }}>취소 &gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

export default SignupPage;