import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";

const KakaoLogoutButton = () => {
    const APP_KEY = "1b52e30797c4a4378739e10fc32ff0c5"; // 카카오 개발자 센터에서 발급받은 JavaScript 키
    const logoutRedirectUri = "https://위아더스.shop/logout"; // 로그아웃 후 리디렉션할 URI
    const logoutUrl = `https://kauth.kakao.com/oauth/logout?client_id=${APP_KEY}&logout_redirect_uri=${logoutRedirectUri}`;
    const navigate = useNavigate();
    useEffect(() => {
        // Kakao 초기화
        if (window.Kakao && !window.Kakao.isInitialized()) {
            window.Kakao.init(APP_KEY);
            console.log("Kakao SDK 초기화 완료");
        }
    }, []);

    const handleLogout = () => {
        // JWT 토큰 처리 (기존 코드)
        const jwtToken = localStorage.getItem("jwt");
        if (jwtToken) {
            localStorage.removeItem("jwt");  // jwt 토큰 삭제
            alert(" 로그아웃 되었습니다.");
            console.log("로그아웃 완료");
            navigate("/login")
        } else {
            alert("이미로그아웃 상태입니다.");
        }
    };

    return (
        <button
            onClick={handleLogout}

        >
            로그아웃
        </button>
    );
};

export default KakaoLogoutButton;
