import Searchbar from "./Searchbar";
import DataTable from "./DataTable";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useMediaQuery} from "react-responsive";
import MobileDataTable from "./MobileDataTable";
import Loading from "./Loading";


const Home = ({islogin}) => {
    // 요청해서 받아온 데이터의 원본
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 1223px)' });
    const divRef = useRef<HTMLDivElement>(null);
    const [keyword, setKeyword] = useState('');
    const [store, setStore] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchedData, setSearchedData] = useState([]);
    const [countrender, setCountrender] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                setLoading(false);
                return;
            }

            // 먼저 searchCount와 maxSearch 값을 확인
            const countResponse = await axios.get(`https://weorthersback.shop/api/searchcount`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });
            console.log("jwt와 함께 요청 보냈음")
            const { searchcount, maxcount } = countResponse.data;
            
            // searchCount가 maxSearch를 넘는지 확인
            if (parseInt(searchcount) >= parseInt(maxcount)) {
                alert("검색 횟수가 최대치를 초과했습니다. 검색을 제한합니다.");
                setLoading(false);
                return; // maxSearch를 초과하면 검색을 중지
            }

            // searchCount가 제한을 넘지 않으면 계속 진행
            setSearchedData([]);
            const response = await axios.get(`https://honbob.site:8443/api/${keyword}`);
            console.log(response + "검색해서 받아옴")
            // 검색 결과가 있으면 searchCount 증가 처리
            if (response.data && response.data.result) {
                // 검색된 결과가 있을 경우
                await axios.post('https://weorthersback.shop/increment', {}, {
                    headers: {
                        Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                    }
                });
                console.log("카운트 +1")
                // 최소 500ms 로딩 상태 유지
                setTimeout(() => {
                    setData(response.data);

                    if(store !== ''){
                        // products 배열을 data1에 할당
                        const data1 = response.data.result?.products || [];
                        // 특정 Mallname 검색 (여러 개의 결과가 있을 수 있음)
                        const storeLower = store.toLowerCase();
                        const data2 = data1.filter(item =>
                            item.mallName?.toLowerCase().includes(storeLower) ||
                            item.productTitle?.toLowerCase().includes(storeLower)
                        );
                        setSearchedData(data2);

                        if (data2.length === 0) {
                            alert("검색한 스토어가 100위 밖입니다.");
                        }
                        console.log("찾은 데이터" + data2);
                    }

                    setLoading(false);
                }, 500);
            } else {
                alert("검색 결과가 없습니다.");
                setLoading(false);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false); // 에러 발생 시 로딩 상태 해제
        }
    };





    const isFirstRender = useRef(true); // 첫 렌더링인지 확인

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // 첫 렌더링 이후에는 false로 설정
            return;
        }

        if (keyword || store) {
            fetchData();
        }
    }, [keyword, store]);


    return(
        <div >
            {/*<div className="bg-red-500 w-full">dd</div>*/}
            <Searchbar data={data} setData={setData} keyword={keyword} setKeyword={setKeyword} store={store} setStore={setStore} islogin={islogin}></Searchbar>
            {
                isDesktopOrLaptop ?
                    <DataTable data={data} setData={setData} loading={loading} setLoading={setLoading} searchedData={searchedData} setSearchedData={setSearchedData}></DataTable>
                    :null
            }


            {
                isMobile ?
                    <MobileDataTable data={data} setData={setData}></MobileDataTable>
                    :null
            }

        </div>
    )
}
export default Home