import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OAuth2 = ({ setAdmin, userinfo, setUserinfo }) => {
    const [loading, setLoading] = useState(true);  // 로딩 상태
    const [data, setData] = useState(null);
    const code = new URL(window.location.href).searchParams.get("code"); // 인가 코드 추출
    const navigate = useNavigate();
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
    };

    useEffect(() => {
        setLoading(true);
        fetch(`https://weorthersback.shop/users/getuserinfo?code=${code}`, {
            method: "POST",
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("서버 응답 데이터:", data);  // 서버로부터 받은 응답 데이터
                setData(data);
                setUserinfo(data.result.user_info);
                console.log("Userinfo:", data.result.user_info);

                setLoading(false);

                // 부모 창으로 메시지 보내기
                if (window.opener) {
                    window.opener.postMessage(
                        {
                            userInfo: data.result.user_info,
                        },
                        "*"
                    );

                    // 부모 창에 메시지를 보낸 후 현재 창 닫기
                    window.close();
                }
            })
            .catch((error) => {
                console.error("오류 발생", error);
                // 부모 창에서 alert 띄우기
                if (window.opener) {
                    window.opener.postMessage({ alertMessage: error.message }, "*");
                    window.close();
                }
                setLoading(false);
            });
    }, [code]);  // `code`가 변경될 때마다 실행

    return (
        <div>
            <p>Oauth2페이지</p>
        </div>
    );
};

export default OAuth2;
