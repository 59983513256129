import React, {useState, useRef, useMemo} from 'react';
import ReactQuill, {Quill} from 'react-quill'; // Quill 에디터
import 'react-quill/dist/quill.snow.css'; // 기본 스타일
import axios from 'axios'; // Axios 추가
import ImageResize from 'quill-image-resize';
Quill.register('modules/ImageResize', ImageResize);
const MyEditor = ({ editorValue, setEditorValue }) => {
    const quillRef = useRef(null); // Quill 에디터에 접근하기 위한 ref

    const handleChange = (value) => {
        setEditorValue(value);
    };

    // 이미지 업로드 처리 함수
    const handleClickImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file); // 백엔드에서 'file'로 받으므로 맞춰줍니다.

                try {
                    // 이미지 업로드 API 호출 (여기서는 S3 API 또는 백엔드 API)
                    const response = await axios.post('https://weorthersback.shop/images/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    // 이미지 업로드 후 S3 URL 받기
                    const imageUrl = response.data; // 반환된 이미지 URL

                    // Quill 인스턴스를 가져와서 이미지 삽입
                    const quill = quillRef.current.getEditor(); // quillRef를 통해 Quill 에디터 인스턴스에 접근
                    const range = quill.getSelection(); // 커서 위치 확인
                    quill.insertEmbed(range.index, 'image', imageUrl); // 이미지 삽입
                } catch (error) {
                    console.error('이미지 업로드 실패:', error);
                }
            }
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["image"],
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image'],
                    [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
                    ['clean']
                ],

                handlers: {
                    image: handleClickImage,
                },
            },
            ImageResize: {
                parchment: Quill.import('parchment')
            }
        };
    }, []);

    return (
        <div>
            <ReactQuill
                ref={quillRef} // Quill 인스턴스에 접근하기 위한 ref 설정

                onChange={handleChange}
                theme="snow"
                modules={modules}
                placeholder="내용을 입력하세요..."
                className="w-full h-[500px]"
            />
        </div>
    );
};

export default MyEditor;
