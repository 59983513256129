import { useState, useEffect } from "react";
import Loading from "./Loading";
import sanitizeHtml from 'sanitize-html';
import SearchCount from "./SearchCount";
import axios from "axios";

const NowSearch2 = ({islogin}) => {
    const [inputValue1, setInputValue1] = useState(""); // 검색어
    const [inputValue2, setInputValue2] = useState(""); // nvmid
    const [keyword, setKeyword] = useState("");
    const [stname, setStname] = useState("");
    const [searchedData, setSearchedData] = useState([]); // 검색된 순위
    const [rank, setRank] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(null);


    // API 호출 함수
    const getNaverShoppingRankByMidnow = async () => {
        setSearchedData([]);
        setRank([]);
        setLoading(true);
        try {
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                setLoading(false);
                return;
            }

            // 먼저 searchCount와 maxSearch 값을 확인
            const countResponse = await axios.get(`https://weorthersback.shop/api/searchcount`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });
            console.log("jwt와 함께 요청 보냈음")
            const {searchcount, maxcount} = countResponse.data;

            // searchCount가 maxSearch를 넘는지 확인
            if (parseInt(searchcount) >= parseInt(maxcount)) {
                alert("검색 횟수가 최대치를 초과했습니다. 검색을 제한합니다.");
                setLoading(false);
                return; // maxSearch를 초과하면 검색을 중지
            }


            const response = await fetch("https://weorthersback.shop/nowsearch2", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",  // JSON 형식으로 요청
                },
                body: JSON.stringify({
                    keyword: keyword,
                    stname: stname,
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json(); // JSON 응답 파싱
            console.log(result);

            // 상태 업데이트
            setRank(result.rank); // rank 설정
            console.log(result.rank)
            setTotal(result.total);
            console.log(result.total)
            setSearchedData(result.matchedItem); // 배열로 반환된 데이터 설정
            console.log(result.matchedItem);
            // rank가 0이고 matchedItem이 비어 있으면 메시지 출력
            if (result.rank.length === 0 || result.matchedItem.length === 0) {
                alert(result.message || "검색결과가 없습니다");
            } else {
                // 검색된 결과가 있을 경우
                await axios.post('https://weorthersback.shop/increment', {}, {
                    headers: {
                        Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                    }
                });
                console.log("검색결과 있으므로 카운트 +1")
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("검색결과가 없습니다");
        } finally {
            setLoading(false);
        }
    };



    // keyword 또는 stname이 변경되면 API 호출
    useEffect(() => {
        if (keyword && stname) {
            getNaverShoppingRankByMidnow();
        }
    }, [keyword, stname]);

    // 검색 버튼 클릭
    const handleSearch = () => {
        if (inputValue1 === "" || inputValue2 === "") {
            alert("키워드와 마켓명 모두 입력해주세요");
            return;
        }
        setKeyword(inputValue1);
        setStname(inputValue2);
    };

    // Enter 키로 검색 실행
    const activeEnter = (e) => {
        if (e.key === "Enter") handleSearch();
    };


    const setTraceItem = async ({ nvmid, storeName, productName }) => {
        try {
            const token = localStorage.getItem("jwt"); // 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");
                return;
            }
            const response1 = await axios.get(`https://weorthersback.shop/rank/list`, {
                headers: {
                    Authorization: `Bearer ${token}`, // JWT 토큰을 Authorization 헤더로 보내기
                },
            });

            // 받아온 데이터의 길이를 체크
            if (response1.data.length >= 10) {
                alert("10개까지만 등록이 가능합니다.");
                return; // 조건 만족 시 함수 종료
            }
            const response = await fetch("https://weorthersback.shop/rank/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // JSON 형식으로 요청
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    keyword: keyword,
                    nvmid: nvmid,
                    storeName: storeName,
                    productName: productName,
                }),
            });
            console.log(response);
            alert("등록되었습니다.");
        } catch (error) {
            console.error("Error during setTraceItem:", error);
            alert("오류 발생");
        } finally {
            console.log("setTraceItem 실행 완료");
        }
    };


    // 검색 버튼 클릭
    const setTrace = () => {
        setTraceItem();
    };
    return (
        <div>
            {loading && <Loading/>}

            <div className={!loading ? "" : "opacity-50"}>
                <div className="flex justify-center pt-[30px]">
                    <div className="sm:content-center relative">
                        <p className="text-[48px] py-3 font-bold">네이버 실시간 순위 검색(마켓명)</p>
                        <span className="text-[20px] text-yellow-400">네이버 쇼핑</span>
                        <span className="text-[20px] text-gray-600">
                            의 최대 400등 까지 조회 가능합니다.
                        </span>
                        <p className="text-gray-400">마켓명 기준 검색은 마켓명을 "포함"한 결과를 가져옵니다.</p>
                    </div>
                </div>
                {
                    islogin ?
                        <div className="pt-6 mr-[20%]">
                            <SearchCount data={searchedData} keyword={keyword} nvmid={stname}></SearchCount>
                        </div>
                        : null
                }
                <div className="flex justify-center space-x-0 pt-6 relative">
                    <input
                        className="pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[300px] h-[65px] text-lg"
                        value={inputValue1}
                        onChange={(e) => setInputValue1(e.target.value)}
                        placeholder="검색어를 입력하세요"
                        onKeyDown={activeEnter}
                    />
                    <input
                        className="pl-12 border-l border-t border-b border-gray-400 w-[300px] h-[65px] text-lg"
                        value={inputValue2}
                        onChange={(e) => setInputValue2(e.target.value)}
                        placeholder="마켓명을 입력하세요"
                        onKeyDown={activeEnter}
                    />
                    <button
                        className="bg-blue-500 rounded-r-md text-white p-2 w-[120px] h-[65px]"
                        onClick={handleSearch}
                        disabled={loading} // 로딩 중 버튼 비활성화
                    >
                        {loading ? "검색 중..." : "검색"}
                    </button>
                </div>
                <div className="flex justify-center pt-6">
                    {searchedData && (
                        <div>
                            {
                                searchedData.map((data, j) => {
                                    const safeTitle = sanitizeHtml(data.title, {
                                        allowedTags: ['b', 'i', 'em', 'strong', 'a'],
                                        allowedAttributes: {a: ['href', 'target']},
                                    });

                                    return (
                                        <div key={j}>
                                            <div>
                                                <div className="flex justify-center pt-12 w-full">
                                                    <div
                                                        className="w-full flex justify-center border-2 border-gray-400 rounded-md bg-gray-100">
                                                        <div className="p-12">
                                                            <img
                                                                src={data.image}
                                                                className="w-[300px] h-auto rounded-md"
                                                                alt={data?.image || "기본 이미지"}
                                                            />
                                                        </div>
                                                        <div className="p-12 pt-6 w-full">
                                                            <div className="text-left">
                                                                <div className="flex">
                                                                    <span
                                                                        className="text-4xl text-blue-500">{rank[j]}위</span>
                                                                    <span className="text-4xl"> /{total}개</span>
                                                                </div>
                                                                {/*<div>*/}
                                                                {/*    <p>{data.nvmid}</p>*/}
                                                                {/*</div>*/}
                                                                {data.link ? (
                                                                    <p className="text-4xl py-6">
                                                                        <a href={data.link} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <span
                                                                                dangerouslySetInnerHTML={{__html: safeTitle}}/>
                                                                        </a>
                                                                    </p>
                                                                ) : (
                                                                    <p className="text-4xl py-6">
                                                                        <span
                                                                            dangerouslySetInnerHTML={{__html: safeTitle}}/>
                                                                    </p>
                                                                )}

                                                                <div className="flex space-x-12">
                                                                    <p className="text-3xl">판매처: {data.mallName}</p>
                                                                    <p className="text-3xl">Nvmid: {data.productId}</p>
                                                                    <button
                                                                        className="bg-blue-500 p-2 rounded-md text-white w-auto h-12 mt-5 ml-12 hover:cursor-pointer"
                                                                        onClick={() =>
                                                                            setTraceItem({
                                                                                nvmid: data.productId,
                                                                                storeName: data.mallName,
                                                                                productName: data.title,
                                                                            })
                                                                        }
                                                                    >
                                                                        순위추적 등록
                                                                    </button>
                                                                </div>
                                                                <div className="flex space-x-10 pt-6">
                                                                    <div>
                                                                        <span className="text-[30px]">가격: </span>
                                                                        <span
                                                                            className="text-blue-500 text-[30px]">{data.lprice}</span>
                                                                        <span className="text-[30px]">원</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default NowSearch2;
