import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Adminpage = () => {
    const navigate = useNavigate();
    const [userDTO, setUserDTO] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [searchedData, setSearchedData] = useState([]);
    const [inputs, setInputs] = useState({ keyword: "" });

    const handleSearch = () => {
        if (!inputs.keyword.trim()) {
            alert("검색어를 입력해주세요!");
            return;
        }
        setKeyword(inputs.keyword);
    };

    const isFirstRender = useRef(true); // 첫 렌더링인지 확인
    const handleKeyDown = (e) => e.key === "Enter" && handleSearch();

    useEffect(() => {
        if (localStorage.getItem("admin") !== "true") {
            alert("관리자 권한이 없습니다");
            navigate("/");
        } else {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        const jwt = localStorage.getItem("jwt");

        try {
            const response = await fetch("https://weorthersback.shop/users/list", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${jwt}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setUserDTO(data);
            console.log(data);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const formatDate = (localDateTime) => {
        const date = new Date(localDateTime); // LocalDateTime을 Date 객체로 변환
        return date.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
    };

    const handleGradeChange = (userIdx, newGrade) => {
        setUserDTO(prevState =>
            prevState.map(user =>
                user.userIdx === userIdx ? { ...user, grade: newGrade } : user
            )
        );
    };

    const handleSave = async () => {
        const jwt = localStorage.getItem("jwt");
        try {
            const response = await fetch("https://weorthersback.shop/users/update", {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${jwt}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userDTO), // 변경된 전체 데이터를 전송
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }


            alert("등급이 저장되었습니다.");

        } catch (error) {
            console.error("Error:", error);
            alert("저장 중 오류가 발생했습니다.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    // keyword가 바뀔 때마다 필터링하여 searchedData에 저장
    useEffect(() => {
        if (!keyword.trim()) {
            setSearchedData(userDTO); // keyword가 빈 값일 경우, 모든 데이터를 표시
            return;
        }

        const filteredData = userDTO.filter((user) => {
            // userName이나 userPhone에 keyword가 포함되면 해당 user를 필터링
            return (
                user.userName?.toLowerCase().includes(keyword.toLowerCase()) ||
                user.userPhone?.toLowerCase().includes(keyword.toLowerCase())
            );
        });

        alert(filteredData.length + "건 검색되었습니다.");
        setSearchedData(filteredData);
    }, [keyword, userDTO]); // keyword나 userDTO가 변경될 때마다 실행

    return (
        <div>
            {/* 검색 및 저장 버튼 */}
            <div className="flex justify-center text-left py-6">
                <div className="w-[90%] pl-[5%]">
                    <div className="flex flex-wrap justify-center space-x-4  py-2">
                        <div className="w-1/6 border-r border-gray-200">
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                     width="24px"
                                     fill="#000000">
                                    <path
                                        d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
                                </svg>
                                <p className="text-lg">1등급(무료)</p>
                            </div>
                            <ul className="text-gray-500 list-disc ml-5">
                                <li>네이버 순위검색 하루 5건</li>
                                <li>네이버 스토어 검색 하루 5건</li>
                                <li>순위추적 기능 제공하지 않음</li>
                            </ul>
                        </div>
                        <div className="w-1/6 border-r border-gray-200">
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                     width="24px"
                                     fill="#000000">
                                    <path
                                        d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
                                </svg>
                                <p className="text-lg">2등급</p>
                            </div>
                            <ul className="text-gray-500 list-disc ml-5">
                                <li>네이버 순위검색 하루 10건</li>
                                <li>네이버 스토어 검색 하루 10건</li>
                                <li>순위추적 기능 제공하지 않음</li>
                            </ul>
                        </div>
                        <div className="w-1/6 border-r border-gray-200">
                            <div className="flex ">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                     width="24px"
                                     fill="#000000">
                                    <path
                                        d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
                                </svg>
                                <p className="text-lg">3등급</p>
                            </div>
                            <ul className="text-gray-500 list-disc ml-5">
                                <li>네이버 순위검색 하루 20건</li>
                                <li>네이버 스토어 검색 하루 20건</li>
                                <li>순위추적 기능 제공 통합 10건</li>
                            </ul>
                        </div>
                        <div className="w-1/6 border-r border-gray-200">
                            <div className="flex ">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                     width="24px"
                                     fill="#000000">
                                    <path
                                        d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
                                </svg>
                                <p className="text-lg">4등급</p>
                            </div>

                            <ul className="text-gray-500 list-disc ml-5">
                                <li>네이버 순위검색 하루 30건</li>
                                <li>네이버 스토어 검색 하루 30건</li>
                                <li>순위추적 기능 제공 통합 10건</li>
                            </ul>
                        </div>
                        <div className="w-1/6 ">
                            <div className="flex ">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                     width="24px"
                                     fill="#000000">
                                    <path
                                        d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
                                </svg>
                                <p className="text-lg">5등급</p>
                            </div>

                            <ul className="text-gray-500 list-disc ml-5">
                                <li>네이버 순위검색 하루 50건</li>
                                <li>네이버 스토어 검색 하루 50건</li>
                                <li>순위추적 기능 제공 통합 10건</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center space-x-0 pt-6 relative">
                <input
                    name="keyword"
                    className="pl-12 border-l border-t border-b border-gray-400 rounded-l-lg w-[500px] h-[65px] text-lg"
                    value={inputs.keyword}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="이름 혹은 전화번호를 입력하세요"
                />
                <button className="bg-blue-500 rounded-r-md text-white p-2 w-[120px] h-[65px]" onClick={handleSearch}>
                    검색
                </button>
            </div>

            <div className="pt-6 justify-end flex">
                <div className="pr-[15%]">
                    <button className="bg-blue-500 text-white p-2 text-xl rounded-md text-center w-40"
                            onClick={handleSave}>
                        저장
                    </button>
                </div>
            </div>

            {/* 사용자 테이블 */}
            <div className="flex justify-center pt-3">
                <div className="relative overflow-x-auto w-3/4">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead
                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                        <tr className="text-lg">
                            <th className="px-6 py-3">회원번호</th>
                            <th className="px-6 py-3">이름</th>
                            <th className="px-6 py-3">메일</th>
                            <th className="px-6 py-3">전화번호</th>
                            <th className="px-6 py-3">가입일</th>
                            <th className="px-6 py-3">등급</th>
                        </tr>
                        </thead>
                        <tbody>
                        {searchedData.length > 0 ? (
                            searchedData.map((user, j) => (
                                <tr
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-lg"
                                    key={j}
                                >
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                        {user.userIdx}
                                    </th>
                                    <td className="px-6 py-4">{user.userName}</td>
                                    <td className="px-6 py-4">{user.userEmail}</td>
                                    <td className="px-6 py-4">{user.userPhone}</td>
                                    <td className="px-6 py-4">{user.regdate ? formatDate(user.regdate) : null}</td>
                                    <td className="px-6 py-4">
                                        <select
                                            defaultValue={user.grade}
                                            className="p-2 "
                                            onChange={(e) => handleGradeChange(user.userIdx, e.target.value)}
                                        >
                                            <option value="0">차단</option>
                                            <option value="1">1(무료)</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">관리자</option>
                                        </select>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">검색된 결과가 없습니다.</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Adminpage;
