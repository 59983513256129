import React, {useState} from 'react';
import * as XLSX from "xlsx/xlsx.mjs";

const ExcelDownload = ({ data, best, sell, price, forDate, quality, searchType, click }) => {
    const keyword = data.result.searchKeyword;
    const [searchtype2, SetSearchtype2] = useState(null);
    const fileName = `${keyword}_검색결과`; // 다운로드할 파일 이름

    // 키 값을 변환할 매핑 객체 생성 (매핑 순서도 중요)
    const keyMapping = {
        "rank": "순위",
        "link": "상품링크",
        "imageUrl": "상품이미지",
        "productTitle": "상품명",
        "category": "카테고리",
        "mallName": "스토어명",
        "mallCount": "판매처수",
        "lowPrice": "판매가",
        "keepCnt": "찜수",
        "reviewCount": "리뷰수",
        "hitStarScore": "클릭점수",
        "saleStarScore": "판매점수",
        "reviewCountStarScore": "리뷰점수",
        "qualityStarScore": "품질점수",
        "relevanceStarScore": "연관성",
        "similarityStarScore": "유사성",
        "recentStarScore": "최신성",
        "rankDownScoreType": "하락위험",
        "reliabilityType": "신뢰도",
        "nvmid": "고유번호"
    };

    // 순서를 보장하는 배열 생성 (keyMapping 객체의 키 순서대로 배열화)
    const orderedKeys = Object.keys(keyMapping);

    // 데이터를 매핑하여 새로운 키로 변환
    const mappedData = (dataToMap) => {
        return dataToMap.map(item => {
            const mappedItem = {};
            // keyMapping에 정의된 순서대로 배열을 처리
            orderedKeys.forEach(key => {
                const newKey = keyMapping[key];
                if (newKey && item[key] !== undefined) {
                    mappedItem[newKey] = item[key];
                }
            });
            return mappedItem;
        });
    };

    // 검색 타입에 맞는 데이터를 가져오는 함수
    const getDataForDownload = () => {
        switch (searchType) {
            case 'A':
                SetSearchtype2('랭킹순');
                return best;
            case 'B':
                SetSearchtype2('클릭순');
                return click;
            case 'C':
                SetSearchtype2('판매순');
                return sell;
            case 'D':
                SetSearchtype2('가격순');
                return price;
            case 'E':
                SetSearchtype2('품질순');
                return quality;
            default:
                return [];
        }
    };

    // 엑셀로 변환하는 함수
    const exportToExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data); // JSON 데이터를 시트로 변환
        const workbook = XLSX.utils.book_new(); // 엑셀 파일 생성하기
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // 생성된 엑셀 파일에 'Sheet1' 시트 추가
        XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : "data.xlsx"); // 엑셀 파일로 저장 후 fileName 이름으로 저장
    };

    // 다운로드 핸들러
    const handleDownload = () => {
        const dataToDownload = getDataForDownload(); // 검색 타입에 맞는 데이터를 가져옴
        const mappedDataForDownload = mappedData(dataToDownload); // 데이터를 변환

        // 변환된 데이터가 있을 경우 엑셀로 다운로드
        if (mappedDataForDownload.length) {
            exportToExcel(mappedDataForDownload);
        } else {
            console.warn('데이터가 없습니다.');
        }
    };

    return (
        <button
            className="bg-green-700 text-white font-bold p-2 rounded-md"
            onClick={handleDownload}
        >
            엑셀 저장
        </button>
    );
};

export default ExcelDownload;
