import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MyChart from "./MyChart";
import MyChart2 from "./MyChart2";
import MyChart3 from "./MyChart3";

const RankTraceDetail = () => {
    const { id } = useParams(); // useParams 훅을 사용하여 URL에서 id를 추출
    const [rankList, setRankList] = useState([]); // rankList 상태 관리
    const navigate = useNavigate();
    const [datatype, setDatatype] = useState('A');
    // select box에서 값이 변경될 때 호출되는 함수
    const handleSelectChange = (event) => {
        setDatatype(event.target.value);
    };

    // useEffect에서 처음에 입력창을 띄운 뒤 getCheckedList 호출
    useEffect(() => {
        // getCheckedList 호출
        getCheckedList();
    }, [id]); // id가 변경될 때마다 호출

    const getCheckedList = async () => {

        try {
            const token = localStorage.getItem("jwt"); // 예시: 로컬 스토리지에 저장된 JWT 토큰 사용
            if (!token) {
                alert("로그인된 상태가 아닙니다.");

                return;
            }
            const response = await axios.get(`https://weorthersback.shop/rank/read/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 Authorization 헤더로 보내기
                }
            });

            // 받아온 데이터를 rankList 상태에 저장
            setRankList(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching rank list:", error);
        } finally {

        }
    };

    // 날짜 포맷팅 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // "YYYY-MM-DD" 형식으로 반환
    };

    // 변동 사항 계산 함수
    const getChange = (currentRank, previousRank) => {
        if (previousRank === undefined) {
            return { symbol: "-", color: "gray", change: "" };  // 변동 없음일 경우 "-"
        }
        const difference = currentRank - previousRank;
        if (difference === 0) {
            return { symbol: "-", color: "gray", change: "" };  // 변동 없음일 경우 "-"
        }
        // 변동량과 색상을 계산하여 리턴
        const change = difference > 0 ? `${difference}` : `${-difference}`;
        const symbol = difference > 0 ? "▼" : "▲";
        const color = difference > 0 ? "blue" : "red";

        return { symbol, color, change };
    };

    // rankList를 날짜 기준으로 최신 순으로 정렬
    const sortedRankList = [...rankList].sort((a, b) => new Date(b.regdate) - new Date(a.regdate));

    return (
        <div className="w-full flex justify-center items-center">
            <div className="w-[90%]">
                <div className="text-left text-xl">
                    <p>
                        검색 키워드 : {rankList.length > 0 ? rankList[0].keyword : '없음'}
                    </p>
                    <p>
                        등록한 nvMid : {rankList.length > 0 ? rankList[0].nvmid : '없음'}
                    </p>
                </div>
                <div className="text-right">
                    <select
                        className="p-2 border border-gray-400 rounded-md mr-12"
                        value={datatype}  // 선택된 값이 state 값에 반영되도록 설정
                        onChange={handleSelectChange}  // 값이 변경될 때 호출될 함수
                    >
                        <option value="A">주간</option>
                        <option value="B">월간</option>

                    </select>
                </div>
                {
                    datatype === "A" ?
                        <div className="flex justify-center mb-4">
                            <MyChart rankList={sortedRankList}></MyChart>
                        </div>
                        :
                        null
                }
                {
                    datatype === "B" ?
                        <div className="flex justify-center mb-4">
                            <MyChart2 rankList={sortedRankList}></MyChart2>
                        </div>
                        :
                        null
                }


                <div className="flex flex-col justify-center">
                    <div className="overflow-x-auto">
                        <table className="text-lg text-left w-full border-collapse text-center">
                            <thead className="bg-gray-100">
                            <tr>
                                <td className="border p-2">순위</td>
                                <td className="border p-2">변동</td>
                                <td className="border p-2">날짜</td>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedRankList.length > 0 ? (
                                sortedRankList.map((rank, index) => (
                                    <tr key={index}>
                                        {
                                            rank.rank === "0" ?
                                                <td className="border p-2">400위 밖</td>
                                                : <td className="border p-2">{rank.rank}</td>
                                        }

                                        <td className="border p-2">
                                            {(() => {
                                                const {
                                                    symbol,
                                                    color,
                                                    change
                                                } = getChange(rank.rank, sortedRankList[index + 1]?.rank);
                                                return (
                                                    <>
                                                        <span style={{color: color}}>{symbol}</span> {/* ▲ or ▼ 색상 */}
                                                        <span style={{
                                                            color: color,
                                                            marginLeft: '5px'
                                                        }}>{change}</span> {/* 변동폭 숫자 */}
                                                    </>
                                                );
                                            })()}
                                        </td>
                                        <td className="border p-2">{formatDate(rank.regdate)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center border p-2">순위 정보가 없습니다.</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankTraceDetail;
