import logo from './logo.svg';
import './App.css';
import Navbar from "./Components/Navbar";
import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "./Components/Home";
import {useEffect, useState} from "react";
import SignPage from "./Components/SignupPage";
import LoginPage from "./Components/LoginPage";
import NaverSearch from "./Components/NaverSearch";
import NaverSearch2 from "./Components/NaverSearch2";
import {useMediaQuery} from "react-responsive";
import Navbar1 from "./Components/Navbar1";
import SignupPage from "./Components/SignupPage";
import Test1 from "./Components/NowSearch";
import OAuth from "./Components/OAuth";
import Mypage from "./Components/Mypage";
import KakaoLogin3 from "./Components/KakaoLogin3";
import SearchCount from "./Components/SearchCount";
import UserUpdate from "./Components/UserUpdate";
import Adminpage from "./Components/Adminpage";
import OAuth2 from "./Components/OAuth2";
import Footer from "./Components/Footer";
import Agreement from "./Components/Agreement";
import Agreement2 from "./Components/Agreement2";
import Agreement3 from "./Components/Agreement3";
import NowSearch from "./Components/NowSearch";
import NowSearch2 from "./Components/NowSearch2";
import TestPage from "./Components/TestPage";
import RankTrace from "./Components/RankTrace";
import Callcenter from "./Components/Callcenter";
import Callcenter2 from "./Components/Callcenter2";
import 'react-quill/dist/quill.snow.css';
import CallcenterDetail from "./Components/CallcenterDetail";
import Mycall from "./Components/Mycall";
import Notice from "./Components/Notice";
import NoticeWrite from "./Components/NoticeWrite";
import NoticeDetail from "./Components/NoticeDetail";
import RankTraceDetail from "./Components/RankTraceDetail"; // 기본 스타일


function App() {
    const [islogin, setIslogin] = useState(false);
    const [navType, setNavType] = useState('A');
    const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 1223px)" });
    const [userinfo, setUserinfo] = useState(null); // userinfo를 저장할 상태
    const navigate = useNavigate();
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        // localStorage에서 userToken 값을 가져와 로그인 상태 판단
        const token = localStorage.getItem("jwt");
        if (token) {
            setIslogin(true); // userToken이 있으면 로그인 상태로 설정
        } else {
            setIslogin(false); // userToken이 없으면 로그아웃 상태로 설정
        }
    }, []); // 컴포넌트 마운트 시 한 번만 실행

    useEffect(() => {
        // localStorage에서 admin 값을 가져와 admin 상태 판단
        const admin = localStorage.getItem("admin");
        if (admin === "true") {
            setAdmin(true); // admin이 "true"이면 admin 상태로 설정
        } else {
            setAdmin(false); // admin이 "false" 또는 없으면 일반 사용자 상태로 설정
        }
    }, []); // 컴포넌트 마운트 시 한 번만 실행

    return (
        <div className="App flex flex-col min-h-[111vh]">
            {/* 헤더 */}
            {isDesktop ? (
                <Navbar navType={navType} setNavType={setNavType} islogin={islogin} setIslogin={setIslogin} admin={admin} />
            ) : null}

            {/* 메인 콘텐츠 */}
            <main className="flex-grow w-full mx-auto py-8">

                <Routes>
                    <Route path='/' element={<Home navType={navType} setNavType={setNavType} islogin={islogin}/>}/>
                    <Route path='/naversearch1' element={<NaverSearch navType={navType} setNavType={setNavType}/>}/>
                    <Route path='/naversearch2' element={<NaverSearch2 navType={navType} setNavType={setNavType}/>}/>
                    <Route path='/signup' element={<SignupPage userinfo={userinfo} setUserinfo={setUserinfo}/>}/>
                    <Route path='/login' element={<LoginPage setIslogin={setIslogin}/>}/>
                    <Route path="/oauth/kakao"
                           element={<OAuth userinfo={userinfo} setUserinfo={setUserinfo} setAdmin={setAdmin}/>}/>
                    <Route path="/oauth/userinfo"
                           element={<OAuth2 userinfo={userinfo} setUserinfo={setUserinfo} setAdmin={setAdmin}/>}/>
                    <Route path="/mypage" element={<Mypage setIslogin={setIslogin}/>}/>
                    <Route path="/userupdate" element={<UserUpdate setIslogin={setIslogin}/>}/>
                    <Route path="/testpage" element={<TestPage/>}/>

                    <Route path="/adminpage" element={<Adminpage admin={admin}/>}/>
                    <Route path="/terms" element={<Agreement2/>}/>
                    <Route path="/privacy" element={<Agreement3/>}/>
                    <Route path="/searchnow" element={<NowSearch islogin={islogin}/>}/>
                    <Route path="/searchnow2" element={<NowSearch2 islogin={islogin}/>}/>
                    <Route path="/admin/callcenter" element={<Callcenter></Callcenter>}></Route>
                    <Route path="/callcenter/register" element={<Callcenter2></Callcenter2>}></Route>
                    <Route path="/callcenter/:id" element={<CallcenterDetail></CallcenterDetail>}></Route>
                    <Route path="/mycall" element={<Mycall></Mycall>}></Route>
                    <Route path="/notice" element={<Notice/>}></Route>
                    <Route path="/notice/:id" element={<NoticeDetail/>}></Route>
                    <Route path="/notice/register" element={<NoticeWrite/>}></Route>
                    <Route path="/ranktrace" element={<RankTrace/>}/>
                    <Route path="/ranktrace/:id" element={<RankTraceDetail/>}/>
                </Routes>
            </main>

            {/* 푸터 */}
            <Footer/>
        </div>
    );
}

export default App;
