import Loading from "./Loading";
import KakaoLogoutButton from "./KakaoLogout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DaumPost from "./DaumPost";

const UserUpdate = ({ setIslogin }) => {
    const [searchModal, setSearchModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userid, setUserid] = useState();
    const [userphone, setUserphone] = useState();
    const [useraddr, setUseraddr] = useState();
    const [detailaddr, setDetailaddr] = useState("");
    const [userInfo, setUserInfo] = useState(null);

    const formatDate = (localDateTime) => {
        const date = new Date(localDateTime);
        return date.toISOString().split("T")[0];
    };

    const navigate = useNavigate();

    const GoLogout = () => {
        setIslogin(false);
        localStorage.clear();
        navigate("/");
    };

    useEffect(() => {
        setLoading(true);

        const jwtToken = localStorage.getItem("jwt");

        if (jwtToken) {
            fetch("https://weorthersback.shop/users/getuser", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch user data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setUserInfo(data);
                    setUserid(data.userId);
                    setUserphone(data.userPhone);
                    setUseraddr(data.userAddress);
                    setDetailaddr(data.userDetailaddress || "");
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setLoading(false);
                });
        } else {
            alert("JWT 토큰이 없습니다");
            setLoading(false);
        }
    }, []);

    const GoUpdate = async () => {
        const userDTO = {
            userIdx: userInfo.userIdx,
            userName: userInfo.userName,
            userId: userid,
            userEmail: userInfo.userEmail,
            userPhone: userphone,
            userAddress: useraddr,
            userDetailaddress: detailaddr,
            regdate: userInfo.regdate,
            grade: userInfo.grade,
        };

        try {
            const response = await fetch(
                `https://weorthersback.shop/users/${userInfo.userIdx}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(userDTO),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to update user data");
            }

            const updatedUser = await response.json();

            alert("사용자 정보가 성공적으로 업데이트되었습니다.");
            setUserInfo(updatedUser);
            navigate("/mypage");
        } catch (error) {
            alert("사용자 정보 업데이트에 실패했습니다.");
        }
    };

    const handleAddressComplete = (fullAddress) => {
        setUseraddr(fullAddress);
        setSearchModal(false);
    };

    return (
        <div>
            <div>
                {loading ? <Loading /> : null}
                {searchModal ? (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-5 rounded shadow-lg w-[70%]">
                            <DaumPost onComplete={handleAddressComplete} />
                            <button
                                className="mt-3 bg-red-600 text-white p-2 rounded"
                                onClick={(e) => {
                                    setSearchModal(false);
                                    e.preventDefault();
                                }}
                            >
                                닫기
                            </button>
                        </div>
                    </div>
                ) : null}
                <div className="flex min-h-screen items-start justify-center bg-gray-100 py-12">
                    <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
                        <div className="text-left">
                            <h1 className="text-4xl font-bold text-blue-600 ml-2 ">
                                마이페이지
                            </h1>
                            <p className="text-gray-600 mt-4 ml-2">
                                환영합니다! 정보를 확인하거나 변경하세요.
                            </p>
                        </div>
                        <div className="mt-6">
                            <div className="border rounded-lg p-6 bg-gray-50">
                                {userInfo ? (
                                    <>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">이름:</span>
                                            <span className="text-gray-900 font-medium">
                                                {userInfo.userName}
                                            </span>
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">ID:</span>
                                            <input
                                                type="text"
                                                className="text-gray-900 font-medium border border-gray-400 rounded-md text-center"
                                                value={userid}
                                                onChange={(e) => setUserid(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">이메일:</span>
                                            <span className="text-gray-900 font-medium">
                                                {userInfo.userEmail}
                                            </span>
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">휴대전화 번호:</span>
                                            <input
                                                type="text"
                                                className="text-gray-900 font-medium border border-gray-400 rounded-md text-center"
                                                value={userphone}
                                                onChange={(e) => setUserphone(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">주소:</span>
                                            <div className="flex">
                                                <input
                                                    type="text"
                                                    className="text-gray-900 font-medium border border-gray-400 rounded-md text-center"
                                                    value={useraddr}
                                                    onChange={(e) => setUseraddr(e.target.value)}
                                                />
                                                <button
                                                    className="rounded-md bg-gray-600 text-white p-1"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSearchModal(true);
                                                    }}
                                                >
                                                    주소찾기 &gt;
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">상세주소:</span>
                                            <input
                                                type="text"
                                                className="text-gray-900 font-medium border border-gray-400 rounded-md text-center"
                                                value={detailaddr}
                                                onChange={(e) => setDetailaddr(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <span className="text-gray-600">가입일:</span>
                                            <span className="text-gray-900 font-medium">
                                                {formatDate(userInfo.regdate)}
                                            </span>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <span className="text-gray-600">상태:</span>
                                            <span className="text-green-600 font-medium">
                                                {userInfo.grade === "1"
                                                    ? "free 등급"
                                                    : userInfo.grade === "2"
                                                        ? "gold 등급"
                                                        : userInfo.grade === "5"
                                                            ? "partner 등급"
                                                            : userInfo.grade}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <p className="text-gray-600">사용자 정보가 없습니다.</p>
                                )}
                            </div>
                        </div>
                        <div className="mt-8 flex gap-4">
                            <button
                                onClick={(e) => GoUpdate(e)}
                                className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                            >
                                수정
                            </button>
                            <button
                                onClick={(e) => GoLogout(e)}
                                className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700"
                            >
                                로그아웃
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserUpdate;
