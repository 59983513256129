import Button from "@mui/material/Button";
import { RiKakaoTalkFill } from 'react-icons/ri';

const KakaoLoginButton = () => {
    const REST_API_KEY = 'dcabad803dd970a060b75ad59756fc23';

    const REDIRECT_URI = "https://위아더스.shop/oauth/userinfo";

    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    const handleKakaoLogin = () => {
        window.open(kakaoURL, "_blank", "width=600,height=800,top=100,left=100,resizable=yes,scrollbars=yes");
    };

    return (
        <div>
            <button
                variant="secondary"
                className="flex w-50 p-2 items-center justify-center gap-2 rounded-md bg-[#FEE500] text-sm font-medium text-gray-700 transition-colors duration-300 hover:border hover:border-yellow-300 hover:bg-[#FFEB3B]"
                onClick={handleKakaoLogin}
            >
                <RiKakaoTalkFill size={150}/>

            </button>
            <p>카카오톡으로 본인인증</p>
        </div>

    );
};

export default KakaoLoginButton;