import { useEffect, useState } from "react";

const NaverSearchCount2= ({test3, keyword,stname}) => {
    const [searchCnt, setSearchCnt] = useState(null);
    const [maxCnt, setMaxCnt] = useState(null);

    // 컴포넌트가 렌더링될 때마다 searchCnt와 maxCnt를 확인하고, 바뀐 경우에만 업데이트
    useEffect(() => {
        handledCount();
    }, [keyword,stname]); // 빈 배열은 컴포넌트 마운트 시 한 번만 실행

    // count 값을 처리하는 함수
    const handledCount = () => {
        const token = localStorage.getItem('jwt');
        console.log("NaverSearchCount2 실행")
        if (token) {
            fetch('https://weorthersback.shop/naver/searchcount', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Authorization 헤더에 Bearer token 포함
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server error');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.searchcount && data.maxcount) {
                        setSearchCnt(data.searchcount);
                        setMaxCnt(data.maxcount);
                    } else {
                        alert("Error: " + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert("An error occurred.");
                });
        } else {
            alert('로그인 후 이용 가능합니다.');
        }
    };

    return (
        <div>
            {/* searchCnt와 maxCnt 값이 있을 때만 표시 */}
            {searchCnt !== null && maxCnt !== null ? (
                <div className="flex justify-center">
                    <p className="text-2xl">검색 가능 횟수: {searchCnt}/{maxCnt}</p>
                </div>
            ) : null}
        </div>
    );
};

export default NaverSearchCount2;
