import React from 'react';
import ApexCharts from 'react-apexcharts';

const MyChart2 = ({ rankList }) => {
    // rankList가 존재하고 배열인지 확인
    const safeRankList = rankList || [];
    // rankList가 7개 이상인 경우 최신 7개만 추출
    const latestRankList = safeRankList.slice(0, 30); // 0번부터 30번까지 자름
    // rankList에서 날짜와 순위 추출
    const dates = latestRankList.map(item => item.date || '');
    let ranks = latestRankList.map(item => {
        const rank = parseInt(item.rank, 10) || 0;
        return rank === 0 ? 400 : rank;
    });


    // 부족한 날짜 수 계산 (30일로 맞추기)
    const fillDays = 30 - dates.length;

    // 날짜와 순위를 배열로 맞춰주되, 부족한 부분은 null로 처리
    const leftNullDates = Array(Math.floor(fillDays / 2)).fill(null);  // 왼쪽에 null 값
    const leftNullRanks = Array(Math.floor(fillDays / 2)).fill(null);  // 왼쪽에 null 값

    const rightNullDates = Array(Math.ceil(fillDays / 2)).fill(null);  // 오른쪽에 null 값
    const rightNullRanks = Array(Math.ceil(fillDays / 2)).fill(null);  // 오른쪽에 null 값

    // 날짜와 순위를 배열로 합치기
    const chartDates = [...leftNullDates, ...dates, ...rightNullDates]; // 가운데로 날짜 채우기
    const chartRanks = [...leftNullRanks, ...ranks, ...rightNullRanks]; // 가운데로 순위 채우기

    // 순위를 반대로 바꿔서 처리
    const safeChartRanks = chartRanks.reverse(); // 순위 역순 처리
    const safeChartDates1 = chartDates.reverse(); // 순위 역순 처리

    // `null` 값 대신 undefined로 처리하여 ApexCharts에서 비어있는 구간을 처리하도록 설정
    const safeChartDates = safeChartDates1.filter(date => date !== null && date !== undefined); // null과 undefined 필터링
    const safeChartRanksFiltered = safeChartRanks.filter(rank => rank !== null && rank !== undefined); // null과 undefined 필터링

    // 차트 옵션
    const chartOptions = {
        chart: {
            id: 'my-chart',
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.5
            },
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#77B6EA', '#545454'], // 선 색상
        dataLabels: {
            enabled: true,
        },
        title: {
            text: '월간 순위 추적 결과', // 제목
            align: 'left',
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // 그리드 색상
                opacity: 0.5
            },
        },
        markers: {
            size: 5, // 마커 크기
        },
        xaxis: {
            categories: safeChartDates,  // 날짜 순서 그대로 사용
            title: {
                text: '날짜', // X축 제목
            },
            padding: {
                left: 10, // 차트 왼쪽 여백
                right: 10, // 차트 오른쪽 여백
            },
        },
        yaxis: {
            title: {
                text: '순위', // Y축 제목
            },
            min: Math.min(...ranks) - 1, // 최소값을 rankList의 최소값으로 설정
            max: Math.max(...ranks) + 1, // 최대값을 rankList의 최대값으로 설정
            reversed: true, // Y축을 반대로 설정하여 작은 값일수록 위로 가도록 설정
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5,
        }
    };

    // 차트 시리즈
    const chartSeries = [
        {
            name: 'Rank',
            data: safeChartRanksFiltered, // 안전한 순위 데이터 사용
        },
    ];

    return (
        <div className="w-full">
            <ApexCharts
                options={chartOptions} // 차트 옵션
                series={chartSeries} // 데이터 시리즈
                type="line" // 차트 종류
                height={350} // 차트 높이
            />
        </div>
    );
};

export default MyChart2;
