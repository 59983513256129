import { useEffect, useState } from "react";
import Loading from "./Loading";
import KakaoLogoutButton from "./KakaoLogout";
import {useNavigate} from "react-router-dom";

const Mypage = ({setIslogin}) => {

    const [loading, setLoading] = useState(false);

    const [userInfo, setUserInfo] = useState(null);
    const formatDate = (localDateTime) => {
        const date = new Date(localDateTime); // LocalDateTime을 Date 객체로 변환
        return date.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 포맷팅
    };
    const navigate = useNavigate();
    const GoLogout = () => {
        setIslogin(false);
        // localStorage.removeItem('userinfo');  // 'userinfo'만 삭제
        // localStorage.removeItem('userToken');
        // localStorage.removeItem('jwt');
        localStorage.clear();

        navigate("/");
    }
    useEffect(() => {
        setLoading(true);  // 로딩 상태를 true로 설정

        // 로컬스토리지에서 jwt 토큰 가져오기
        const jwtToken = localStorage.getItem("jwt");

        if (jwtToken) {
            // JWT 토큰을 Authorization 헤더에 담아서 GET 요청을 보냅니다.
            fetch('https://weorthersback.shop/users/getuser', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,  // Authorization 헤더에 Bearer token 포함
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }
                    return response.json();  // JSON 형태로 응답받기
                })
                .then(data => {
                    console.log(data);
                    setUserInfo(data);  // 유저 정보 상태에 저장
                    setLoading(false);  // 로딩 상태를 false로 변경
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);  // 로딩 상태를 false로 변경
                });
        } else {
            alert('JWT 토큰이 없습니다');
            setLoading(false);  // 로딩 상태를 false로 변경
        }
    }, []);  // 컴포넌트가 처음 렌더링될 때 한번만 실행

    return (
        <div>
            {loading ? <Loading /> : null}

            <div className="flex min-h-screen items-start justify-center bg-gray-100 py-12">
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
                    <div className="text-left">
                        <h1 className="text-4xl font-bold text-blue-600 ml-2 ">마이페이지</h1>
                        <p className="text-gray-600 mt-4 ml-2">환영합니다! 정보를 확인하거나 변경하세요.</p>
                    </div>
                    <div className="mt-6">
                        <div className="border rounded-lg p-6 bg-gray-50">
                            {/* 사용자 정보가 로딩되었을 때 표시 */}
                            {userInfo ? (
                                <>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">이름:</span>
                                        <span className="text-gray-900 font-medium">{userInfo.userName}</span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">ID:</span>
                                        <span className="text-gray-900 font-medium">{userInfo.userId}</span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">이메일:</span>
                                        <span className="text-gray-900 font-medium">{userInfo.userEmail}</span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">휴대전화 번호:</span>
                                        <span className="text-gray-900 font-medium">{userInfo.userPhone}</span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">주소:</span>
                                        <span
                                            className="text-gray-900 font-medium"
                                            style={{wordWrap: 'break-word', maxWidth: '40ch'}}
                                        >
                                          {userInfo.userAddress}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">상세주소:</span>
                                        <span className="text-gray-900 font-medium">{userInfo.userDetailaddress}</span>
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-gray-600">가입일:</span>
                                        <span className="text-gray-900 font-medium">{formatDate(userInfo.regdate)}</span>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-gray-600">상태:</span>
                                        <span className="text-green-600 font-medium">
                                            {userInfo.grade === '1' ? 'free 등급' :
                                                userInfo.grade === '2' ? 'gold 등급' :
                                                    userInfo.grade === '5' ? 'partner 등급' : userInfo.grade}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <p className="text-gray-600">사용자 정보가 없습니다.</p>
                            )}
                        </div>
                    </div>
                    <div className="mt-8 flex gap-4">
                        <button
                            className="flex-1 py-2 px-4 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                            onClick={() => {navigate("/userupdate")}}
                        >
                            정보 수정
                        </button>
                        <div
                            className="flex-1 py-2 px-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 transition duration-300 hover:cursor-pointer"
                            onClick={() => {GoLogout()}}

                        >
                            <KakaoLogoutButton/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mypage;
