import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import {useNavigate, useParams} from "react-router-dom";

const NoticeDetail = () => {
    const { id } = useParams(); // useParams 훅을 사용하여 URL에서 id를 추출
    const [data, setData] = useState(null);
    const [sanitizedContent, setSanitizedContent] = useState("");

    const navigate = useNavigate()
    // 게시글 및 댓글 데이터 가져오기
    useEffect(() => {
        const fetchData = async () => {
            const url = `https://weorthersback.shop/notice/${id}`; // 게시글 정보 URL
            try {
                const response = await axios.get(url);
                setData(response.data);

                // content sanitization
                const sanitized = DOMPurify.sanitize(response.data.noticeContent);
                setSanitizedContent(sanitized);


            } catch (error) {
                console.error("데이터 가져오기 실패", error);
            }
        };

        fetchData();
    }, []); // 첫 렌더링 시에만 실행




    // 날짜 포맷팅 (data가 설정된 후에만 실행되도록)
    const formattedDate = data ? new Date(data.regdate).toLocaleDateString('en-CA') : '';
    // 댓글 날짜 포맷팅
    const formattedDate1 = (regdate) => {
        return regdate ? new Date(regdate).toLocaleDateString('en-CA') : '';
    };

    const goDelete = () => {
        const id = data.noticeIdx;
        const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기

        if (!token) {
            alert("로그인된 상태가 아닙니다.");
            navigate("/login");
            return;
        }

        // 삭제 확인을 위한 confirm 창
        const isConfirmed = window.confirm("정말 삭제하시겠습니까?");

        if (isConfirmed) {
            const fetchData1 = async () => {
                const url = `https://weorthersback.shop/notice/${id}`; // 게시글 정보 URL
                try {
                    const response = await axios.delete(url, {
                        headers: {
                            Authorization: `Bearer ${token}`, // JWT 토큰을 Authorization 헤더에 추가
                        },
                    });
                    console.log(response);
                    alert("삭제되었습니다.");
                    navigate("/notice"); // 삭제 후 목록 페이지로 리디렉션
                } catch (error) {
                    console.error("데이터 가져오기 실패", error);
                    alert("삭제 실패했습니다.");
                }
            };

            fetchData1();
        } else {
            console.log("삭제 취소");
        }
    };



    return (
        <div>
            <div className="flex justify-center p-6 bg-gray-100">
                {data ? (
                    <div className="bg-white rounded-lg shadow-md w-full max-w-6xl">
                        <div className="p-6">
                            <div className="flex justify-between">
                                <h1 className="text-4xl font-bold text-gray-800 text-left">{data.noticeTitle}</h1>
                                <p className="text-sm text-gray-500 mt-2">{` 작성일: ${formattedDate}`}</p>
                            </div>
                            <div className="py-6">
                                <hr className="w-full"></hr>
                            </div>

                            <div className="mt-4">
                                <div
                                    dangerouslySetInnerHTML={{__html: sanitizedContent}}
                                    className="prose max-w-none"
                                />
                            </div>
                        </div>
                        {
                            localStorage.getItem("admin") === "true" ?
                                <div className="text-right mr-12">
                                    <hr className="ml-12 my-12"></hr>
                                    <button className="bg-red-600 text-white p-2 rounded-md mb-6"
                                            onClick={() => {
                                                goDelete()
                                            }}>삭제
                                    </button>
                                </div>
                                :null
                        }

                    </div>
                ) : null}

            </div>

        </div>

    );

};

export default NoticeDetail;
