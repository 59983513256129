import { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import {useNavigate, useParams} from "react-router-dom";

const CallcenterDetail = () => {
    const { id } = useParams(); // useParams 훅을 사용하여 URL에서 id를 추출
    const [data, setData] = useState(null);
    const [sanitizedContent, setSanitizedContent] = useState("");
    const [comments, setComments] = useState([]); // 댓글 목록 상태 추가
    const [newComment, setNewComment] = useState(""); // 새 댓글 상태 추가
    const navigate = useNavigate()
    // 게시글 및 댓글 데이터 가져오기
    useEffect(() => {
        const fetchData = async () => {
            const url = `https://weorthersback.shop/callcenter/${id}`; // 게시글 정보 URL
            try {
                const response = await axios.get(url);
                setData(response.data);

                // content sanitization
                const sanitized = DOMPurify.sanitize(response.data.content);
                setSanitizedContent(sanitized);


                // 댓글 정보 가져오기
                const commentUrl = `https://weorthersback.shop/comments`; // 댓글 URL
                const commentResponse = await axios.get(commentUrl, {
                    params: {
                        id: id, // 파라미터로 ID 전달
                    }
                });
                setComments(commentResponse.data); // 댓글 상태 업데이트
                console.log(commentResponse.data); // 응답 데이터 확인

            } catch (error) {
                console.error("데이터 가져오기 실패", error);
            }
        };

        fetchData();
    }, []); // 첫 렌더링 시에만 실행

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (newComment.trim()) {
            try {
                const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기

                if (!token) {
                    alert("로그인된 상태가 아닙니다.");
                    navigate("/login");
                    return;
                }

                const commentDTO = {
                    comment: newComment,
                    callcenterIdx: data.callcenterIdx

                };
                console.log(commentDTO);

                // 댓글 API 호출
                const response = await axios.post(
                    `https://weorthersback.shop/comments`,
                    commentDTO,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`, // 토큰을 헤더에 추가
                        },
                    }
                );

                // 새 댓글 추가 후 댓글 목록 업데이트
                setComments((prevComments) => [response.data, ...prevComments]);
                setNewComment(""); // 입력란 초기화
            } catch (error) {
                console.error("댓글 작성 실패", error);
                alert("댓글 작성에 실패했습니다.");
            }
        }
    };


    // 날짜 포맷팅 (data가 설정된 후에만 실행되도록)
    const formattedDate = data ? new Date(data.regdate).toLocaleDateString('en-CA') : '';
    // 댓글 날짜 포맷팅
    const formattedDate1 = (regdate) => {
        return regdate ? new Date(regdate).toLocaleDateString('en-CA') : '';
    };
    const timeAgo = (regdate) => {
        const now = new Date();
        const then = new Date(regdate); // 서버에서 받은 시간을 그대로 사용

        const diff = now - then; // 밀리초 차이

        const seconds = Math.floor(diff / 1000); // 초 단위
        const minutes = Math.floor(seconds / 60); // 분 단위
        const hours = Math.floor(minutes / 60); // 시간 단위
        const days = Math.floor(hours / 24); // 일 단위

        // 년과 월 차이를 계산 (정확한 월 차이 계산)
        const years = now.getFullYear() - then.getFullYear();
        let months = now.getMonth() - then.getMonth() + (years * 12);

        // 월이 음수로 나오면 이전 년도에서 계산되었기 때문에 그만큼 차이를 보정
        if (now.getMonth() < then.getMonth()) {
            months += 12;
        }

        // 년/월 계산 후 표시
        if (years > 0) {
            return `${years} 년 전`;
        } else if (months > 0) {
            return `${months} 개월 전`;
        } else if (days > 0) {
            return `${days} 일 전`;
        } else if (hours > 0) {
            return `${hours} 시간 전`;
        } else if (minutes > 0) {
            return `${minutes} 분 전`;
        } else {
            return `${seconds} 초 전`;
        }
    };



    return (
        <div className="flex justify-center p-6 bg-gray-100">
            {data ? (
                <div className="bg-white rounded-lg shadow-md w-full max-w-6xl">
                    <div className="p-6">
                        <div className="flex justify-between">
                            <h1 className="text-4xl font-bold text-gray-800 text-left">{data.title}</h1>
                            <p className="text-sm text-gray-500 mt-2">{`작성자: ${data.writer} | 작성일: ${formattedDate}`}</p>
                        </div>
                        <div className="py-6">
                            <hr className="w-full"></hr>
                        </div>

                        <div className="mt-4">
                            <div
                                dangerouslySetInnerHTML={{__html: sanitizedContent}}
                                className="prose max-w-none"
                            />
                        </div>
                    </div>

                    {/* 댓글 입력 폼 */}
                    <div className="p-6 border-t border-gray-200">
                        <form onSubmit={handleCommentSubmit}>
                        <textarea
                            className="w-full p-4 border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-blue-500"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="댓글을 입력하세요"
                        />
                            <div className="text-right mt-4">
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
                                >
                                    댓글 작성
                                </button>
                            </div>
                        </form>
                    </div>

                    {/* 댓글 목록 */}
                    <div className="p-6 border-t border-gray-200">
                        <h3 className="text-xl font-semibold text-gray-700 mb-4 rounded-md text-left">댓글 목록</h3>
                        <ul className="space-y-4 text-left">
                            {comments.map((comment, j) => (
                                <div key={j} className="bg-gray-100 p-2">
                                    <div className="flex font-bold text-lg p-2">
                                        {
                                            comment.writer === "작성자" ?
                                                <p className="font-bold">{comment.writer}</p> :
                                                <p className="font-bold text-blue-500">{comment.writer}</p>
                                        }

                                        <p className="ml-6 text-gray-500">{timeAgo(comment.regdate)}</p> {/* 날짜 포맷팅 */}
                                    </div>
                                    <div className="bg-white rounded-md p-2">
                                        <p>{comment.comment}</p>
                                    </div>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : null}
        </div>
    );

};

export default CallcenterDetail;
