import React from 'react';
import * as XLSX from "xlsx/xlsx.mjs";

const NaverExcelDownload = ({ useExcel, keyword }) => {
    const fileName = `${keyword}_검색결과`; // 다운로드할 파일 이름

    // 키 값을 변환할 매핑 객체 생성 (매핑 순서도 중요)
    const keyMapping = {
        "rank": "순위",
        "card.product.productName": "상품명",
        "card.product.productUrl.pcUrl": "상품링크",
        "card.product.mallName": "스토어명",
        "card.product.nvMid": "nvmid",
        "card.product.averageReviewScore": "평점",
        "card.product.totalReviewCount": "리뷰수"
    };

    // 데이터 변환 함수
    const transformedData = useExcel.map((item, index) => {
        const transformedItem = {};

        // keyMapping 순서대로 처리
        for (const [key, mappedKey] of Object.entries(keyMapping)) {
            if (key === "rank") {
                // rank는 index + 1로 설정
                transformedItem[mappedKey] = index + 1;
            } else {
                // key 경로를 따라 JSON 데이터에서 값을 찾음
                const value = key.split('.').reduce((acc, curr) => acc && acc[curr], item);

                // 값이 존재하는 경우에만 추가
                if (value !== undefined) {
                    transformedItem[mappedKey] = value;
                }
            }
        }

        return transformedItem;
    });

    // 엑셀로 변환하는 함수
    const exportToExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data); // JSON 데이터를 시트로 변환
        const workbook = XLSX.utils.book_new(); // 엑셀 파일 생성하기
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // 생성된 엑셀 파일에 'Sheet1' 시트 추가
        XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : "data.xlsx"); // 엑셀 파일로 저장 후 fileName 이름으로 저장
    };

    // 다운로드 핸들러
    const handleDownload = () => {
        // 변환된 데이터가 있을 경우 엑셀로 다운로드
        if (transformedData.length) {
            exportToExcel(transformedData);
        } else {
            console.warn('데이터가 없습니다.');
        }
    };

    return (
        <button
            className="bg-green-700 text-white font-bold p-2 rounded-md"
            onClick={handleDownload}
        >
            엑셀 저장
        </button>
    );
};

export default NaverExcelDownload;
