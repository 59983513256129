import React, { useState } from "react";
import axios from "axios";
import MyEditor from "./MyEditor";
import { useNavigate } from "react-router-dom";

const Callcenter2 = () => {
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // 기본 폼 제출 동작 방지

        // 카테고리가 "카테고리를 선택해주세요"일 경우 경고 후 함수 종료
        if (category === "") {
            alert("카테고리를 선택해 주세요");
            return; // 폼 제출을 막기 위해 함수 종료
        }

        const token = localStorage.getItem("jwt"); // JWT 토큰 가져오기

        if (!token) {
            alert("로그인된 상태가 아닙니다.");
            navigate("/login");
            return;
        }

        // 요청 데이터
        const data = {
            title: title,
            category: category,
            content: editorValue,
        };

        try {
            console.log(data);

            // API 요청
            const response = await axios.post(
                "https://weorthersback.shop/callcenter",
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // 토큰 추가
                    },
                }
            );

            console.log("응답:", response.data);
            alert("게시물이 등록되었습니다.");
            navigate("/callcenter"); // 폼 제출 후 페이지 이동
        } catch (error) {
            // 서버에서 반환한 에러 메시지 확인
            if (error.response) {
                // 서버에서 4xx/5xx 에러가 발생한 경우
                console.error("서버 응답 오류:", error.response.data);
                alert(`서버 오류: ${error.response.data}`); // 서버에서 반환한 오류 메시지
            } else if (error.request) {
                // 요청이 서버에 도달했으나 응답을 받지 못한 경우
                console.error("요청 오류:", error.request);
                alert("서버 응답을 받지 못했습니다. 나중에 다시 시도해주세요.");
            } else {
                // 다른 종류의 오류가 발생한 경우
                console.error("오류 발생:", error.message);
                alert("알 수 없는 오류가 발생했습니다.");
            }
        }
    };


    return (
        <div className="flex justify-center">
            <div className="w-[80%] space-x-4">
                <form onSubmit={handleSubmit}>
                    <div className="flex justify-between w-full py-6">
                        <div className="w-3/5">
                            <input
                                type="text"
                                id="first_name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="제목을 입력해 주세요"
                                required
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="text-left w-1/10 pl-12 my-1">
                            <select
                                id="categories"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option>카테고리를 선택해주세요</option>
                                <option value="단순문의">단순문의</option>
                                <option value="사업문의">사업문의</option>
                                <option value="오류">오류</option>
                                <option value="회원가입/등급 문의">회원가입/등급 문의</option>
                                <option value="기타">기타</option>
                            </select>
                        </div>
                        <div className="justify-end">
                            <button
                                type="submit"
                                className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                            >
                                등록
                            </button>
                        </div>
                    </div>
                    <div>
                        <MyEditor value={editorValue} setEditorValue={setEditorValue} />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Callcenter2;
